section.caseInfoWrapper{
section.caseInfo{
    padding-top: 150px;
    padding-bottom: 125px;
    margin-bottom: 0;
    .caseTitle{
        margin-bottom: 20px;
    }
    .caseTags{
        margin-bottom: 60px;
    }
    .caseWrapper{
        display: flex;
        flex-direction: column;
        gap: 0px;
        .caseDesc{
            width: 80%;
            margin: 60px auto;
        }
        img{
            max-width:100%;
            width: 100%;
        }
        // .caseCover{
        //     width: 100%;
        //     aspect-ratio: 1.8;
        // }
    }
    .work-team{
        margin-top: 250px;
        .work-team-wrapper{
            display: flex;
            flex-wrap: wrap;
            gap: 40px;
            margin-top: 60px;
            .big-wrapper{
                display: flex;
                flex-wrap: wrap;
                gap: 40px;
            }
            .small-wrapper{
                display: flex;
                flex-wrap: wrap;
                gap: 40px;
            }

            .gallery-item{
                position: relative !important;
                top: auto !important;
                left: auto !important;
                right: auto !important;
            }
            .gallery-item-small{
                position: relative !important;
                top: auto !important;
                left: auto !important;
                right: auto !important;
                width: 250px;
                height: 250px;
                border-radius: 250px;
            }
            .unshown{
                display: none;
            }
        }
    }
    .look-hear{
        margin-top: 250px;
        .portfolio-wrapper{
            margin-top: 60px;
            display: flex;
            justify-content: space-between;
            flex-wrap:wrap;
            .portfolio-item{
                flex-direction: column-reverse;
                justify-content: start;
                width: 48%;
                margin-bottom: 0;
                .portfolio-item-text{
                    width: 100%;
                    .portfolio-item-hashtag{
                        width: 100%;
                        max-width: 100%;
                        margin: 25px 0;
                    }
                    .portfolio-item-title{
                        width: 100%;
                    }
                }
            }
            .portfolio-cover{
                width: 100%;
            }
        }
    }
    .projectResult{
        margin-top: 250px;
        .project-link-wrap{
            margin-top: 70px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .project-link-desc{
                width: 50%;
                position: relative;
                padding-left: 90px;
            }
            .project-link-svg{
                position: absolute;
                height: 90%;
                aspect-ratio: 0.82;
                left: 0;
            }
            .projectResultLink:hover{
                transition: all .5s ease-in-out; 
            }
        
        }
        .slick-slider .slick-slide{
            /* max-height: 80vh; */
            height: auto;
        }
        .project-slider-nav .slick-slide{
            /* max-height: 10vh; */
            height: auto;
        }
        .slick-prev, .slick-next{
            cursor: none;
            width: 50px;
            height: 50px;
        }
        .slick-next {
            right: 20px;
        }
        .slick-prev{
            left: 10px;
            z-index: 9;
        }
        
        .slick-prev::before, .slick-next::before{
            content: '';
            position: absolute;
            display: block;
            background-image: url('../../components/image/slick-arrow.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            width: 60px;
            aspect-ratio: 0.4;
            top: -45px;
        }
        .slick-next::before{
            transform: rotate(180deg);
        }
        .project-slider{
            .slider-title{
                margin-bottom: 50px;
            }
            .slider-item{
                div{
                    border-radius: 40px;
                }
            }
            .slider-img{
                border-radius: 40px;
            }
        }
        .project-slider-nav{
            margin-top: 20px;
            .slider-nav-img{
                border-radius: 20px;
            }
        }
        .project-slider-nav .slick-slide{
            margin-right: 10px;
        }
    }
}
section.discuss{
    padding-top: 125px;
}
margin-bottom: 0;
}
@media (max-width: 991px){
    section.caseInfoWrapper section.caseInfo .projectResult .project-link-wrap .project-link-desc{
        width: 100%;
    }
    .projectResultLink{
        margin-top: 40px;
        margin-left: 0;
    }
    section.caseInfoWrapper section.caseInfo .projectResult .slick-prev::before, section.caseInfoWrapper section.caseInfo .projectResult .slick-next::before {
        width: 40px;
        top: -20px;
    }
    section.caseInfoWrapper section.caseInfo .projectResult .slick-prev::before{
        right: 15px;
    }
    section.caseInfoWrapper section.caseInfo .projectResult .slick-next::before {
        right: -15px;
    }

}
@media (max-width: 575px){
    .projectResultLink{
        margin-left: 0;
    }
}

@media (max-width: 767px) {
    .project-slider-nav .slick-slide{
        max-width: 100px;
    }
    section.caseInfoWrapper section.caseInfo .projectResult .project-slider .slider-item div{
        border-radius: 20px;
    }
    section.caseInfoWrapper section.caseInfo .projectResult .project-slider .slider-img{
        border-radius: 20px;
    }
    section.caseInfoWrapper section.caseInfo .projectResult .project-slider-nav .slider-nav-img{
        border-radius: 10px;
    }
}

@media (max-width: 575px) {
    section.caseInfoWrapper section.caseInfo .projectResult .project-slider .slider-item div{
        border-radius: 10px;
    }
    section.caseInfoWrapper section.caseInfo .projectResult .project-slider .slider-img{
        border-radius: 10px;
    }
    section.caseInfoWrapper section.caseInfo .projectResult .project-slider-nav .slider-nav-img{
        border-radius: 5px;
    }
    section.caseInfoWrapper section.caseInfo .projectResult .slick-prev::before, section.caseInfoWrapper section.caseInfo .projectResult .slick-next::before {
        width: 20px;
        top: -5px;
    }
    section.caseInfoWrapper section.caseInfo .projectResult .slick-prev::before{
        right: 35px;
    }
    section.caseInfoWrapper section.caseInfo .projectResult .slick-next::before {
        right: -15px;
    }
}



