@font-face {
    font-family: 'NeutralFace';
    src: url('../fonts/NeutralFaceRegular.ttf');
}
@font-face {
    font-family: 'CeraPro';
    src: url('../fonts/CeraPro-Regular.ttf');
}
/*Specification*/
::-webkit-scrollbar {
    width: 0;
  }
body{
	padding: 0; 
	margin: 0;
    font-family: "CeraPro";
	font-size: 30px;
	font-weight: 400;
	color: #f1f1f1;
    background-color: #131313;
    /* cursor: none; */
}
a{
    transition: color .5s ease-in-out; 
}
a:hover{
    color: #01EB81;
    transition: color .5s ease-in-out; 
}
/* .cursor-wrapper{
    position: relative;
}
.cursor-auto{
    background: url('../image/cursor-main.svg');
    width: 25px;
    height: 25px;
    background-size: cover;
    position: fixed;
    pointer-events: none;
    z-index: 2000;
}
.cursor-auto-addition{
    background: url('../image/cursor-main-add.svg');
    width: 9.7px;
    height: 9.7px;
    background-size: cover;
    position: fixed;
    top: -20px;
    left: 10.15px;
    pointer-events: none;
    animation-delay: 1s;
    opacity: 1;
    transition: opacity .5s ease-in-out;
    z-index: 2000;
}
.cursor-auto.cursor-pointer{
    background: url('../image/cursor-pointer.svg');
    width: 25px;
    height: 25px;
    background-size: cover;
}
.cursor-auto-addition.cursor-pointer{
    opacity: 0;
    transition: opacity .5s ease-in-out;
} */
*{
	box-sizing: border-box;
}
ul, li {
	display: block;
	padding: 0;
	margin: 0;
}

p, h1, h2, h3, h4, h5, h6 {
	padding: 0;
	margin: 0;
}
h1{
    font-size: 120px;
}
h1, h2, h3, h4, h5, h6{
    font-family: "NeutralFace";
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-weight: 400;
}
h2{
    font-size: 90px;
}
.h2{
    font-family: "NeutralFace";
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-weight: 400;
    font-size: 90px;
}
h3{
    font-size: 60px;
    letter-spacing: 0.05em;
}
p{
    font-family: "CeraPro";
    font-weight: 400;
    line-height: 1.1em;
}
a {
	text-decoration: none;
	margin: 0;
	padding: 0;
	color: #f1f1f1;
    /* cursor: none; */
}
img{
	max-width: 100%;
	display: block;
	object-fit: cover;
}
input{
	outline: none;
	border: none;
    padding: 0;
    margin: 0;
}
button{
	outline: none;
	border: none;
    background-color: transparent;
}
.container {
	max-width: 1440px;
	margin: 0 auto;
}
section{
    margin-bottom: 250px;
}
.section-title-wrapper{
    padding-top: 50px;
}
.btn{
    font-family: "CeraPro";
    font-weight: 400;
    width: 571px;
    height: 80px;
    background-color: transparent;
    border-radius: 8px;
    border: 1px solid #01EB81;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #01EB81;
    text-transform: uppercase;
    letter-spacing: -0.02em;
    transition: background-color .5s ease-in-out, color .5s ease-in-out;
}
.btn:hover{
    background-color: #01EB81;
    color: #131313;
    transition: background-color .5s ease-in-out, color .5s ease-in-out;
}
.arrow-home{
    position: absolute;
    background-image: url('../image/arrow.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 78px;
    height: 78px;
    position: absolute;
    opacity: 1;
    transition: opacity .5s ease-in-out;
    top: -80px;
}
.arrow-home::before{
    content: '';
    background-image: url('../image/arrow-hover.svg');
    width: 78px;
    height: 78px;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity .5s ease-in-out;
}
.arrow-home:hover:before {
    opacity: 1;
    transition: opacity .5s ease-in-out;
}

/* .mainHeader.header{
    padding-top: 138px;
    margin-bottom: 250px; 
    overflow: hidden; 
} */
.bg-img{
    background: url("../image/bg-img.svg" ) no-repeat center;
    left: 0;
    position: absolute;
    top: -435px;
    width: 100%;
    height: 1950px;
    z-index: -10;  
}
.offer-section{
    padding-top: 138px;
}
.offer{
    display: flex;
    justify-content: space-between;
}
.offer-title{
    font-size: 120px;
    width: 890px;
    font-weight: 400;
    line-height: 1.2em;
}
.offer-title > div{
    display: inline;
}
div.ne{
    background-image: url("../image/ne.svg");
    background-size: contain;
    width: 259px;
    aspect-ratio: 2.54;
    display: inline-block;
    margin-right: -5px;
    background-repeat: no-repeat;
}
.offer-text{
    font-size: 20px;
    width: 447px;
}
span.green{
    color: #01EB81;
}
.green{
    color: #01EB81;
}
.menu-wrapper{
    position: fixed;
    right: 0px;
    z-index: 10;
    top: 277px;
    width: 150px;
    /* aspect-ratio: 0.375; */
    height: 400px;
    border: 1px solid #f1f1f1;
    border-right: 0px;
    border-radius: 800px 0px 0px 800px / 1000px 0px 0px 1000px;
    background-color: #131313;
    transition: height .2s ease-in-out, width .2s ease-in-out, top .2s ease-in-out, background-color .2s ease-in-out, top .2s ease-in-out;
}
.menu-subwrapper{
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
}
.menu-preview{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 35px;
}
.menu-wrapper:hover{
    width: 160px;
    transition: height .2s ease-in-out, width .2s ease-in-out, top .2s ease-in-out, background-color .2s ease-in-out, top .2s ease-in-out;
}
.menu-icon{
    position: absolute;
    z-index: -10;
    top: 50%;
    margin-left: -40px;
    margin-top: -60px;
    background-image: url("../image/menu.png");
    width: 200px;
    height: 120px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 25%;
    /* cursor: none; */
    transition: margin-left .2s ease-in-out;
}
.menu-icon-transform{
    background-image: url("../image/menu-close.png");
    margin-left: -300px;
    transition: margin-left .5s ease-in-out;
    z-index: 1000;
}
.menu-wrapper-transform{
    width: 2000px;
    /* aspect-ratio: 2.35; */
    height: 850px;
    max-height: 100vh;
    min-height: 700px;
    right: 0;
    top: 43px;
    padding-left: 384px;
    z-index: 10;
    background-color: #131313;
    transition: height .2s ease-in-out, width .2s ease-in-out, top .2s ease-in-out, background-color .2s ease-in-out;
}
.menu-wrapper.menu-wrapper-transform:hover{
    width: 2000px;
    height: 850px;
    max-height: 100vh;
    min-height: 700px;
    transition: height .2s ease-in-out, width .2s ease-in-out, top .2s ease-in-out, background-color .2s ease-in-out;
}
.navigation{
    display: flex;
    visibility: hidden;
    opacity: 0;
    transition: opacity 1.8s ease-in-out;
}
.navigation.show{
    flex-direction: row;
    visibility: visible;
    opacity: 1;
    transition: opacity 1.8s ease-in-out;
}
ul.menu-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.social-wrapper{
    display: flex;
    flex-direction: column;
    margin-right: 250px;
}
.social-wrapper > a{
    display: block;
    margin-bottom: 35px;
    width: 77px;
    height: 77px;
    transform: scale(1);
    transition: transform .5s ease-in-out;
    background-repeat: no-repeat;
    background-size: contain;
}
.social-wrapper > a:last-child{
    margin-bottom: 0;
}
.social-wrapper > a:hover{
    transform: scale(1.07);
    transition: transform .5s ease-in-out;
}
.behance{
    background-image: url("../image/Behance.png");
}
.instagram{
    background-image: url("../image/Instagram.png");
}
.wtsp{
    background-image: url("../image/WhatsApp.png");
}
.telegram{
    background-image: url("../image/Telegram.png");
}
.linkedin{
    background-image: url("../image/vc.png");
}
.mail{
    background-image: url("../image/mail.png");
}



.menu-list > .menu-item{
    margin-bottom: 31px;
}
.menu-list > .menu-item:last-child{
    margin-bottom: 0px;
}
.menu-item > a{
    color: #f1f1f1;
    transition: color .5s ease-in-out;
}
.menu-item > a:hover{
    color:#01EB81;
    transition: color .5s ease-in-out;
}

.menu-btn{
    margin-top: 93px;
    color: #f1f1f1;
    font-size: 32px;
    transition: background-color .5s ease-in-out;
}
.menu-btn:hover{
    background-color: #01EB81;
    transition: background-color .5s ease-in-out;
}


.video-wrapper{
    width: 100%;
    position: relative;
}
.zoom-video > video{
    max-width: 100%;
    border-radius: 92px;
    z-index: 5;
    object-fit: cover;
    aspect-ratio: 1.8;
    transform: scale(0.13);
    margin-top: -470px;
}

.offer-btn-img > svg{
    margin-left: 14px;
    margin-top: 5px;
    margin-right: -6px;
    transition: fill .5s ease-in-out;
    fill: #01EB81;
    fill-rule: evenodd;
}
.offer-btn{
    width: 100%;
    /* max-width: 1440px; */
    color: #f1f1f1;
    font-size: 48px;
    letter-spacing: -0.02em;
    height: 168px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    font-weight: 400;
    margin-top: -300px;
    /* position: absolute; */
    background-color: #131313;
    z-index: 2;
}
.offer-btn:hover{
    background-color: #01EB81;
}
.offer-btn:hover .offer-btn-img > svg{
    fill: #131313;
    transition: fill .5s ease-in-out;
}

.about-section{
    margin-top: 1500px;
}
.about-title-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;
}
.about-subdesc-wrapper{
    margin-bottom: 50px;
    width: 100%;
}
.about-desc-wrapper{
    margin-bottom: 60px;
    width: 100%;
}
.about-desc{
    font-size: 60px;
}
.about-desc-small{
    display: none;
}
.about-wrapper{
    display: flex;
    justify-content: space-between;
    text-align: left;
}
.about-item{
    text-transform: uppercase;
    font-size: 48px;
    color: #f1f1f1;
    letter-spacing: -0.02em;
    position: relative;
    margin-bottom: 60px;
}
.about-item-wrapper:first-child{
    width: 374px;
    /* margin-right: 68px; */
    position: relative;
}
.about-item-wrapper:first-child > .about-item{
    padding-left: 74px;
}
.about-item-wrapper:first-child > .about-item:first-child::before{
    content: '';
    position: absolute;
    background: url('../image/star1.svg') no-repeat center;
    background-size: contain;
    width: 44px;
    aspect-ratio: 1.26;
    left: 0px;
    top: 10px;
}
.about-item-wrapper:first-child > .about-item:last-child::before{
    content: '';
    position: absolute;
    background: url('../image/star2.svg') no-repeat center; 
    background-size: contain;
    width: 36px;
    aspect-ratio: 0.78;
    left: 0px;
    top: 12px;
}

.about-item-wrapper:nth-child(2){
    width: 531px;
    /* margin-right: 68px; */
    padding-top: 120px;
}
.about-item-wrapper:nth-child(2) > .about-item{
    padding-left: 66px;
}
.about-item-wrapper:nth-child(2) > .about-item:first-child::before{
    content: '';
    position: absolute;
    background: url('../image/star2.svg') no-repeat center;
    background-size: contain;
    width: 36px;
    aspect-ratio: 0.78;
    left: 0px;
    top: 12px;
}
.about-item-wrapper:nth-child(2) > .about-item:last-child::before{
    content: '';
    position: absolute;
    background: url('../image/star3.svg') no-repeat center;
    background-size: contain;
    width: 39px;
    aspect-ratio: 0.83;
    left: 0px;
    top: 12px;
}

.about-item-wrapper:last-child{
    width: 336px;
    padding-top: 240px;
}
.about-item-wrapper:last-child > .about-item{
    padding-left: 68px;
}
.about-item-wrapper:last-child > .about-item::before{
    content: '';
    position: absolute;
    background: url('../image/star3.svg') no-repeat center;
    background-size: contain;
    width: 39px;
    aspect-ratio: 0.83;
    left: 0px;
    top: 12px;
}

.about-postscr{
    width: 328px;
    font-size: 20px;
    padding-left: 68px;
}
.about-item-wrapper:first-child::after{
    content: '';
    position: absolute;
    background: url('../image/arrow-pic.svg') no-repeat center;
    background-size: contain;
    width: 246px;
    height: 287px;
    left: 66px;
    bottom: -160px;
}



.portfolio-title-wrapper_main{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 120px;
}
.portfolio-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;
}
.portfolio-item:last-child{
    margin-bottom: 0;
}
.portfolio-item-text{ 
    width: 370px;
}
.portfolio-item-hashtag{
    font-size: 20px;
    margin-bottom: 20px;
    max-width: 370px;
}
.portfolio-item-title{
    display: block;
    color: #F1F1F1;
    transition: color .5s ease-in-out;
    font-size: 60px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-family: "NeutralFace";
    font-weight: 400;
    width: auto;
    /* word-wrap: break-word; */
}
.portfolio-item:first-child>.portfolio-item-text>.portfolio-item-title{
    width: 300px;
}
.portfolio-item-title:hover{
    color: #01EB81;
    transition: color .5s ease-in-out;
}
.portfolio-cover{
    width: 943px;
    border: 1px solid #F1F1F1;
    border-radius: 40px;
    position: relative;
    transform: scale(0.99);
    transition: transform .5s ease-in-out;
    aspect-ratio: 1.78;
}
.portfolio-cover-img{
    border-radius: 40px;
}
/* .portfolio-cover::after{
    content: '';
    background: url('../image/Behance.png') no-repeat center;
    background-size: contain;
    position: absolute;
    width: 52px;
    height: 52px;
    z-index: 10;
    left: 20px;
    bottom: 20px;
    opacity: 0;
    transition: opacity .5s ease-in-out;
} */
.portfolio-cover::before{
    content: '';
    background: url('../image/arrow-hover.svg');
    position: absolute;
    width: 52px;
    height: 52px;
    z-index: 10;
    transform: rotate(180deg);
    right: 20px;
    bottom: 20px;
    opacity: 0;
    transition: opacity .5s ease-in-out;
}
.portfolio-cover:hover{
    transform: scale(1);
    transition: transform .5s ease-in-out;
}

.portfolio-cover:hover::before{
    opacity: 1;
    transition: opacity .5s ease-in-out;
}
.portfolio-cover:hover::after{
    opacity: 1;
    transition: opacity .5s ease-in-out;
}
.portfolio-item-text:last-child{
    margin-bottom: 0;
}
.portfolio-item-text:hover +.portfolio-cover{
    transform: scale(1);
    transition: transform .5s ease-in-out;
} 
.portfolio-item-text:hover +.portfolio-cover::before,
.portfolio-item-text:hover +.portfolio-cover::after{
    opacity: 1;
    transition: opacity .5s ease-in-out;
} 



section.discuss{
    margin-bottom: 260px;
}
.discuss-title{
    position: relative;
}
.discuss-title::after{
    content: '';
    position: absolute;
    background-size: 100%;
    background-repeat: no-repeat;
    right: 0px;
    top: 0px;
    width: 300px;
    height: 400px;
    background-image: url("../image/pic1.svg");
}
.discuss-wrapper{
    margin-bottom: 75px;
    display: flex;
    margin-top: 60px;
}
.discuss-item{
    position: relative;
}
.discuss-item:first-child{
    margin-left: 183px;
    width: 477px;
}
.discuss-item::before{
    content: '';
    position: absolute;
    background-size: 100%;
    background-repeat: no-repeat;
    top: 20px;
    left: -90px;
}
.discuss-item:first-child::before{
    width: 46px;
    height: 87px;
    background-image: url("../image/1.svg");
}
.discuss-item:nth-child(2){
    margin-left: -40px;
    margin-top: 138px;
    width: 327px;
}
.discuss-item:nth-child(2)::before{
    width: 45px;
    height: 80px;
    background-image: url("../image/2.svg");
}
.discuss-item:last-child{
    margin-top: 275px;
    margin-left: 77px;
    width: 415px;
}
.discuss-item:last-child::before{
    top: 10px;
    width: 44px;
    height: 86px;
    background-image: url("../image/3.svg");
}
a.green{
    margin-top: 5px;
}
.star-desc{
    font-size: 20px;
    position: relative;
    width: 243px;
    margin-top: 30px;
    margin-left: 30px;
}
.star-desc::before{
    content: '';
    position: absolute;
    background-size: 100%;
    background-repeat: no-repeat;
    width: 13px;
    height: 15px;
    top: 5px;
    left: -30px;
    background-image: url("../image/star.svg");
}
.discuss-btn{
    position: relative;
    margin-top: 120px;
    width: 100%;
    color: #f1f1f1;
    font-size: 48px;
    letter-spacing: -0.02em;
    height: 168px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    font-weight: 400;
    background-color: #131313;
}
.discuss-btn::before{
    content: '';
    position: absolute;
    background-size: 100%;
    background-repeat: no-repeat;
    width: 250px;
    height: 600px;
    top: -500px;
    left: 30px;
    transform: scale(0.6);
    background-image: url("../image/arrow.gif");
}
.discuss-btn:hover{
    background-color: #01EB81;
}
.discuss-btn-img > svg{
    margin-left: 14px;
    margin-top: 5px;
    margin-right: -6px;
    transition: fill .5s ease-in-out;
    fill: #01EB81;
    fill-rule: evenodd;
}
.discuss-btn:hover .discuss-btn-img > svg{
    fill: #131313;
    transition: fill .5s ease-in-out;
}
span.star{
    position: relative;
}
span.star::after{
    content: '';
    position: absolute;
    background-size: 100%;
    background-repeat: no-repeat;
    width: 13px;
    height: 15px;
    top: 0px;
    right: -10px;
    background-image: url("../image/star.svg");
}

footer.footer{
    padding-bottom: 150px;
}
.footer-links-wrapper{
    margin-top: 25px;
    display: flex;
    justify-content: end;
    margin-bottom: 75px;
    position: relative;
}
.footer-social-wrapper{
    display: flex;
    flex-direction: row;
    width: 612px;
    justify-content: space-between;
    margin-top: -120px;
    margin-right: 20px;

    /* position: absolute;
    right: 0;
    top: -120px; */
}
.footer-social-wrapper > a{
    display: block;
    margin-bottom: 35px;
    width: 77px;
    height: 77px;
    transform: scale(1);
    transition: transform .5s ease-in-out;
    background-repeat: no-repeat;
    background-size: contain;
}
.footer-social-wrapper > a:last-child{
    margin-bottom: 0;
}
.footer-social-wrapper > a:hover{
    transform: scale(1.07);
    transition: transform .5s ease-in-out;
}
.footer-privacy-wrapper{
    display: flex;
    justify-content: space-between;
}
.footer-img{
    background: url('../image/nestudio-logo.gif') no-repeat center center;
    background-size: contain;
    width: 1440px;
    height: 700px;
    display: block;
    margin-bottom: 0px;
}
section.discuss{
    margin-bottom: 0;
}
.about-title-wrapper_main{
    margin-bottom: 60px;
}



/*  RESPONSIVE  */

/* * ExtraLarge: 1600px - 2000px */ 
@media (max-width: 2000px){
    .container {
		max-width: 1440px;
	}
    .menu-wrapper-transform{
        width: 100%;
        height: 90vh;
    }
    .menu-wrapper-transform, .menu-wrapper.menu-wrapper-transform:hover {
        height: 90vh;
    }
    .menu-wrapper.menu-wrapper-transform:hover{
        width: 100%;
        height: 90vh;
    }
    .social-wrapper{
        margin-right: 100px;
    }
    /* .menu-icon-transform{
        transform: translateX(-3000%);
    } */
}
/* * ExtraLarge2: 1490px - 1600px*/
@media (max-width: 1600px){
    .container {
		max-width: 1280px;
	}
    .footer-img{
        width: 100%;
    }
    .menu-wrapper{
        width: 100px;
        height: 267px;
    }
    .menu-wrapper:hover{
        width: 120px;
    }
    .menu-wrapper-transform{
        width: 100%;
        height: 700px;
        top: 20px;
    }
    .menu-wrapper.menu-wrapper-transform:hover{
        width: 100%;
        height: 700px;
    }
    .zoom-video > video {
        margin-top: -420px;
    }
    .offer-btn{
        margin-top: -280px;
    }
    .social-wrapper{
        margin-right: 150px;
    }
    .btn{
        width: 440px;
    }
    .offer-btn{
        width: 100%;
    }
    .discuss-btn{
        width: 100%;
    }
    .discuss-btn::before{
        transform: scale(0.5);
    }
    .discuss-title::after{
        top: 80px;
    }
    .discuss-item:first-child {
        margin-left: 90px;
    }
    .footer-social-wrapper{
        top: -100px;
    }
    .about-item-wrapper:nth-child(2) {
        width: 531px;
        padding-top: 100px;
    }
    .about-item-wrapper:last-child {
        width: 336px;
        padding-top: 200px;
    }
}
/* * ExtraLarge3: 1199px - 1490px мой ноут */ 
@media (max-width: 1490px){
    .container {
		max-width: 1110px;
	}
    .menu-wrapper-transform {
        padding-left: 300px;
    }
    .footer-img{
        width: 100%;
    }
    .portfolio-item-hashtag{
        width: 300px;
    }
    .discuss-title::after{
        right: 0;
        top: 80px;
    }
    .discuss-btn::before{
        width: 200px;
        background-size: contain;
        left: 0;
        top: -450px;
    }
    .discuss-wrapper{
        justify-content: space-between;
    }
    .discuss-item:first-child{
        width: auto;
        margin-left: 90px;
    }
    .discuss-item:nth-child(2){
        width: auto;
        margin-top: 200px;
    }
    .discuss-item:last-child{
        width: auto;
        margin-top: 400px;
        margin-left: 0;
    }
    .discuss-btn::before{
        transform: scale(0.6);
    }
    .offer-title{
        font-size: 96px;
    }
    div.ne{
        width: 199.2px;
        margin-right: -5px;
    }
    .zoom-video > video {
        margin-top: -360px;
    }
    .offer-btn{
        margin-top: -220px;
        font-size: 36px;
    }
    .offer-text{
        font-size: 15px;
    }
    .offer-btn-img > svg{
        transform: scale(0.8);
        margin-left: 11px;
        margin-top: 10px;
        margin-right: -10px;
    }
    .about-section {
        margin-top: 1300px;
    }
    h2{
        font-size: 72px;
    }
    .h2{
        font-size: 72px;
    }
    body{
        font-size: 24px;
    }
    .btn{
        width: 440px;
    }
    .offer-btn{
        width: 100%;
    }
    .discuss-btn{
        width: 100%;
        font-size: 36px;
    }
    .about-desc {
        font-size: 48px;
    }
    .about-item{
        font-size: 34px;
    }
    .about-item-wrapper:first-child > .about-item:first-child::before{
        width: 34px;
        top: 6px;
    }
    .about-item-wrapper:first-child > .about-item:last-child::before {
        width: 28px;
        top: 6px;
    }
    .about-item-wrapper:nth-child(2) > .about-item:first-child::before{
        width: 28px;
        top: 6px;
    }
    .about-item-wrapper:nth-child(2) > .about-item:last-child::before{
        width: 30px;
        top: 6px;
    }
    .about-item-wrapper:last-child > .about-item::before{
        width: 30px;
        top: 6px;
    }
    .portfolio-item-hashtag{
        font-size: 18px;
    }
    .portfolio-item-title{
        font-size: 48px;
    }
    .discuss-item::before{
        background-size: 77%;
        top: 5px;
        left: -90px;
    }
    .discuss-item:last-child::before{
        top: 5px;
    }
    .discuss-btn-img > svg{
        transform: scale(0.8);
        margin-left: 11px;
        margin-top: 10px;
        margin-right: -10px;
    }
    .portfolio-item:first-child>.portfolio-item-text>.portfolio-item-title{
        width: 250px;
    }
    .footer-social-wrapper{
        margin-top: -110px;
    }
}

/* * Large: 992px - 1199px */ 
@media (max-width: 1199px){
    .container {
		max-width: 960px;
	}
    .menu-wrapper{
        top: 0;
        width: 150px;
        height: 150px;
        /* right: 20px; */
        margin-left: -200px;
        margin-top: -75px;
        transform: rotate(-90deg);
        transition: height .5s ease-in-out, width .5s ease-in-out, top .5s ease-in-out, margin-left .5s ease-in-out, left 1s ease-in-out;
    }
    .menu-wrapper:hover{
        width: 150px;
        transition: height .5s ease-in-out, width .5s ease-in-out, top .5s ease-in-out, margin-left .5s ease-in-out, left 1s ease-in-out; 
    }
    .menu-wrapper-transform{
        transform: rotate(0deg);
        /* left: 50%;
        margin-left: -50%; */
        padding-left: 75px;
        border: none;
        border-radius: 0;
        margin-top: 0;
        top: 0;
        height: 100vh;
        transition: height .5s ease-in-out, width .5s ease-in-out, top .5s ease-in-out, margin-left .5s ease-in-out, left 1s ease-in-out; 
    }
    .menu-wrapper.menu-wrapper-transform:hover{
        height: 100vh;
        transition: height .5s ease-in-out, width .5s ease-in-out, top .5s ease-in-out, margin-left .5s ease-in-out, left 1s ease-in-out; 
    }
    .menu-icon{
        background-image: url('../image/menu-burger.svg');
        background-repeat: no-repeat;
        transform: rotate(-90deg);
        top: 50%;
        margin-left: -60px;
    }
    .menu-icon-transform{
        background-image: url('../image/menu-burger-close.svg');
        transform: rotate(-90deg);
        margin-left: 0;
        position: absolute;
        top: 65px;
        left: 90%;
        margin-left: -120px;
    }
    .menu-preview{
        padding-top: 0px;
    }
    .social-wrapper{
        flex-direction: row;
        position: absolute;
        bottom: 100px;
        margin-right: 0;
        flex-wrap: wrap;
    }
    .social-wrapper>a:not(:last-child){
        margin-right: 30px;
    }
    .offer{
        display: inline;
    }
    .offer-text{
        position: absolute;
        top: 650px;
        width: 382px;
    }
    .zoom-video>video{
        margin-top: -320px;
    }
    .offer-btn {
        margin-top: -200px;
    }
    .about-section {
        margin-top: 1400px;
    }
    .btn {
        width: 300px;
    }
    .offer-btn{
        width: 100%;
    }
    .discuss-btn{
        width: 100%;
    }
    .menu-btn{
        width: 400px;
        margin-top: 50px;
    }

    .about-wrapper{
        display: block;
    }
    .about-item-wrapper:first-child{
        width: auto;
    }
    .about-item-wrapper:nth-child(2){
        padding-top: 0;
        margin-left: 50%;
        width: auto;
    }
    .about-item-wrapper:last-child{
        padding-top: 0;
        margin-left: 50%;
        width: auto;
    }
    .about-item-wrapper:last-child > .about-item {
        padding-left: 68px;
    }
    .about-item-wrapper:first-child::after{
        bottom: -350px;
    }
    .bg-img{
        background: url('../image/bg-img-adapt.png') no-repeat right;
        top: -725px;
        /* left: -100px; */
    }
    .about-wrapper{
        position: relative;
    }
    .about-item{
        margin-bottom: 40px;
    }
    .footer-img{
        margin-bottom: -100px;
    }
    .portfolio-item:first-child>.portfolio-item-text>.portfolio-item-title{
        width: 300px;
    }
    .footer-links-wrapper{
        justify-content: start;
    }
    .footer-social-wrapper{
        margin-top: 0px;  
    }
}

/* Medium: 768px - 991px */
@media (max-width: 991px) {
	.container {
        width: 100%;
		max-width: 720px;
	}
	body{
	    cursor:auto;
	}
	.cursor-auto{
        display:none;  
    }
    .cursor-auto-addition{
        display:none;
    }
    .zoom-video>video{
        margin-top: -250px;
        /* margin-left: 100px; */
        transform: scale(0.13) translateX(1000px);
        border-radius: 20px;
        /* transition: .5s ease-in-out; */
    }
    .portfolio-cover{
        border-radius: 20px;
    }
    .offer-btn{
        margin-top: -120px;
        height: 120px;
    }
    .offer-title{
        width: 100%;
    }
    .about-section {
        margin-top: 1300px;
        position: relative;
    }
    .about-section_main{
        position: relative;
    }
    .about-detail{
        position: absolute;
        bottom: -150px;
    }
    .about-desc{
        width: 630px;
        letter-spacing: 0.05em;
    }
    section.portfolio_main{
        margin-top: 350px;
        position: relative;
    }
    .about-item-wrapper:first-child::after{
        background-size: contain;
        width: 179px;
        height: 209px;
        bottom: -300px;
    }
    .about-item-wrapper:nth-child(2){
        margin-left: 40%;
    }
    .about-item-wrapper:last-child{
        margin-left: 40%;
    }
    .portfolio-item{
        flex-direction: column;
        margin-bottom: 60px;
    }
    .portfolio-cover{
        width: 100%;
    }
    .portfolio-cover-img{
        border-radius: 20px;
    }
    .portfolio-title-wrapper_main{
        margin-bottom: 60px;
    }
    .portfolio-item-text{
        max-width: 100%;
        width: 100%;
        margin-bottom: 20px;
    }
    .portfolio-item-hashtag{
        max-width: 100%;
        width: 100%;
    }
    .portfolio-item-title{
        width: 100%;
    }
    .portfolio-item:first-child>.portfolio-item-text>.portfolio-item-title{
        width: 100%;
    }
    .btn {
        width: 370px;
    }
    .offer-btn{
        width: 100%;
    }
    .discuss-btn{
        width: 100%;
        height: 120px;
        margin-top: 490px;
    }
    .discuss-btn::before{
        width: 190px;
        height: 480px;
        top: -400px;
    }
    .discuss-title::after{
        top: -20px;
        width: 220px;
        right: 0;
    }
    .menu-btn{
        width: 400px;
    }
    .portfolio-btn{
        position: absolute;
        bottom: -100px;
    }
    .discuss-wrapper{
        position: relative;
    }
    .discuss-item{
        position: absolute;
    }
    .discuss-item:first-child{
        width: 50%;
    }
    .discuss-item:nth-child(2){
        width: 262px;
        top: 140px;
        left: 250px;
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
    }
    .discuss-item:last-child {
        width: 300px;
        top: 280px;
        margin-top: 0;
        left: 350px;
    }
    .footer-img {
        margin-bottom: -150px;
    }

}

/* Small: 576px - 767px */
@media (max-width: 767px) {
	.container {
		max-width: 540px;
	}
    .bg-img{
        background: url('../image/bg-img-adapt2.svg') no-repeat center;
        top: 0;
        height: 507px;
    }
    .menu-wrapper{
        padding-left: 0;
    }
    /* .menu-preview{
        margin-top: -200px;
    } */
    .menu-icon.menu-icon-transform{
        margin-left: -100px;
    }
    .social-wrapper > a {
        width: 65px;
        height: 65px;
        background-size: contain;
    }
    .social-wrapper>a:not(:last-child) {
        margin-right: 25px;
    }
    .discuss-item:nth-child(2){
        top: 140px;
        left: 180px;
    }
    .discuss-item:last-child {
        width: 280px;
        left: 262px;
    }
    .btn{
        font-size: 24px;
    }
    h1{
        font-size: 80px;
    }
    h2{
        font-size: 60px;
    }
    .h2{
        font-size: 60px;
    }
    h3{
        font-size: 40px;
    }
    body{
        font-size: 20px;
    }
    .portfolio-item-title{
        font-size: 40px;
    }
    .portfolio-item-hashtag {
        font-size: 16px;
    }
    .about-desc {
        font-size: 40px;
        width: 538px;
    }
    .about-item {
        font-size: 28px;
    }
    .offer-title {
        font-size: 80px;
    }
    div.ne {
        width: 166px;
        margin-right: -10px;
    }
    .offer-btn{
        font-size: 28px;
        margin-top: -70px;
    }
    .offer-btn-img > svg{
        transform: scale(0.62);
        margin-left: 0px;
        margin-right: -20px;
    }
    .zoom-video>video {
        margin-top: -200px;
        transform: scale(0.13) translateX(1400px);
    }
    .offer-text {
        top: 700px;
    } 
    .about-section {
        margin-top: 1200px;
    }
    .about-item-wrapper:nth-child(2){
        margin-left: 35%;
    }
    .about-item-wrapper:last-child{
        margin-left: 35%;
    }
    .about-item-wrapper:first-child::after {
        left: 0px;
        bottom: -300px;
    }
    .discuss-title::after {
        top: -100px;
        width: 160px;
        right: 0;
    }
    .discuss-btn{
        font-size: 28px;
    }
    .discuss-btn::before {
        width: 140px;
        height: 480px;
        top: -350px;
        left: 0;
    }
    .discuss-btn-img > svg {
        transform: scale(0.62);
        margin-left: 0px;
        margin-top: 10px;
        margin-right: -20px;
    }
    .footer-img {
        margin-bottom: -200px;
    }
    .footer-social-wrapper{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .footer-social-wrapper > a {
        width: 65px;
        height: 65px;
        background-size: contain;
    }
    .footer-social-wrapper>a:not(:last-child){
        margin-right: 0;
    }
    .social-wrapper{
        bottom: 200px;
    }
    .star-desc{
        font-size: 16px;
    }
}

/* ExtraSmall1: 576px - 500px */
@media (max-width: 575px) {
	.container {
        max-width: 490px;
	}  
    /* *{
        overflow-y: hidden;
    } */
    .menu-wrapper{
        top: 0;
        width: 100px;
        height: 100px;
        /* right: 50px; */
        margin-left: -50px;
        margin-top: -50px;
    }
    .menu-wrapper:hover{
        width: 100px;
        height: 100px;
        margin-left: -50px;
        margin-top: -50px;
    }
    .menu-preview{
        width: 100%;
    }
    .menu-icon{
        width: 150px;
        height: 150px;
        margin-bottom: 0px;
        left: 10%;
        top: 50%;
        margin-top: -75px;
    }
    .menu-icon.menu-icon-transform{
        width: 100px;
        height: 100px;
        margin-left: -50px;
        top: 100px;
        left: 90%;
    }
    .menu-wrapper.menu-wrapper-transform{
        /* height: 600px; */
        right: 0;
        margin-left: 0;
    }
    .menu-wrapper.menu-wrapper-transform:hover{
        /* height: 600px; */
        margin-left: 0;
    }
    .navigation.show{
        width: 100%;
    }
    .menu-list{
        width: 100%;
    }

    .social-wrapper > a {
        width: 40px;
        height: 40px;
        background-size: contain;
    }
    .social-wrapper>a:not(:last-child) {
        margin-right: 15px;
    }
    .btn{
        font-size: 16px;
        width: 90%;
        height: 50px;
        left: 50%;
        margin-left: -45%;
    }
    .submit-button{
        margin-left: 0;
    }

    .menu-btn{
        width: 80%;
        left: 0;
        margin-left: 0;
    }
    h1{
        font-size: 48px;
    }
    h2{
        font-size: 32px;
    }
    .h2{
        font-size: 32px;
    }
    h3{
        font-size: 24px;
    }
    body{
        font-size: 16px;
    }
    .offer-title{
        font-size: 48px;
    }
    .portfolio-title-wrapper {
        margin-bottom: 40px;
    }
    .portfolio-item {
        margin-bottom: 40px;
    }
    .portfolio-item-hashtag {
        font-size: 14px;
    }
    .portfolio-item-title {
        font-size: 24px;
    }
    .portfolio-item-hashtag {
        margin-bottom: 10px;
    }
    .about-title-wrapper{
        margin-bottom: 40px;
    }
    .about-item {
        font-size: 20px;
    }
    .about-desc {
        font-size: 24px;
        width: 100%;
    }
    .about-subdesc-wrapper {
        margin-bottom: 30px;
    }
    .about-desc-wrapper {
        margin-bottom: 30px;
        width: 100%;
    }
    .about-subdesc{
        font-size: 14px;
    }
    div.ne {
        width: 99.6px;
        margin-right: -5px;
    }
    .zoom-video>video{
        margin-top: -160px;
        transform: scale(0.13) translateX(0px);
        border-radius: 40px;
        /* position: absolute; */
    }
    .offer-btn{
        margin-top: -90px;
        left: 0;
        margin-left: 0;
    }
    .offer-text {
        top: 360px;
        width: 80%;
        font-size: 14px;
    }
    .about-section{
        margin-top: 1100px;
    }
    .discuss-wrapper{
        margin-top: 40px;
    }
    .discuss-item:first-child {
        width: 80%;
    }
    .discuss-item:nth-child(2) {
        width: 80%;
        left: 90px;
        top: 100px;
    }
    .discuss-item:last-child {
        width: 80%;
        left: 90px;
        top: 180px;
    }
    .discuss-btn::before {
        display: none;
    }
    .discuss-item::before {
        top: -5px;
    }
    .star-desc{
        font-size: 12px;
        width: 80%;
        margin-left: 20px;
        margin-top: 20px;
    }
    .star-desc::before{
        transform: scale(0.7);
        top: 0;
        left: -20px;
    }
    .discuss-title::after {
        display: none;
    }
    .offer-btn{
        width: 100%;
        height: 50px;
        font-size: 18px;
        border-radius: 10px;
    }
    .offer-btn-img > svg {
        transform: scale(0.4);
        margin-left: -15px;
        margin-right: -25px;
        margin-top: 5px;
    }
    .discuss-btn{
        width: 100%;
        height: 50px;
        font-size: 18px;
        border-radius: 10px;
        margin-top: 320px;
        left: 0;
        margin-left: 0;
    }
    span.star::after{
        width: 8px;
        height: 8px;
        right: -5px;
    }
    .discuss-btn-img > svg {
        transform: scale(0.4);
        margin-left: -15px;
        margin-right: -25px;
        margin-top: 5px;
    }
    .about-postscr{
        width: auto;
        font-size: 14px;
    }
    .about-item-wrapper>.about-item::before{
        transform: scale(0.5);
    }
    .about-item-wrapper:first-child::after {
        background-size: contain;
        width: 115px;
        height: 134px;
        left: 0px;
        bottom: -250px;
    }
    .about-item {
        margin-bottom: 20px;
        padding-left: 20px;
    }
    .about-item-wrapper:first-child > .about-item:first-child::before,
    .about-item-wrapper:first-child > .about-item:last-child::before {
        top: -5px;
    }
    .about-item-wrapper:nth-child(2) > .about-item:first-child::before,
    .about-item-wrapper:nth-child(2) > .about-item:last-child::before {
        top: -5px;
    }
    .about-item-wrapper:last-child > .about-item::before {
        top: -5px;
    }
    .about-item-wrapper:first-child > .about-item {
        padding-left: 40px;
    }
    .about-item-wrapper:nth-child(2) > .about-item {
        padding-left: 40px;
    }
    .about-item-wrapper:last-child > .about-item {
        padding-left: 40px;
    }
    .social-wrapper {
        bottom: 50px;
    }
    footer.footer{
        margin-top: -180px;
        padding-bottom: 50px; 
    }
    .footer-links-wrapper {
        margin-bottom: 40px;
    }
    .footer-social-wrapper > a {
        width: 40px;
        height: 40px;
        background-size: contain;
    }
    section.portfolio_main{
        margin-top: 250px;
        margin-bottom: 200px;
    }

}

/* ExtraSmall2: 500px - 400px */
@media (max-width: 500px) {
	.container {
		max-width: 390px;
	}  
    /* .menu-wrapper{
        right: 100px;
    } */
    .zoom-video>video{
        margin-top: -135px;
        transform: scale(0.13) translateX(400px);
        border-radius: 20px;
    }
    .offer-btn{
        margin-top: -60px;
    }
    .footer-img {
        margin-bottom: -250px;
    }
    footer.footer{
        margin-top: -200px;
    }
    .about-desc{
        display: none;
    }
    .about-desc-small{
        display: block;
    }
    .discuss-item::before {
        transform: scale(0.8);
    }
    .discuss-item:last-child::before {
        top: 0px;
    }
    .arrow-home{
        transform: scale(0.8);
    }
}

/* ExtraSmall3: 400px - 380px */
@media (max-width: 400px) {
	.container {
		padding: 0 15px;
        max-width: 100%;
	} 
    /* .discuss-item{
        padding-left: 50px;
    } */
    .discuss-item::before {
        background-size: 77%;
        top: 0px;
        left: -50px;
    }
    .discuss-item:first-child {
        width: 80%;
        margin-left: 50px;
    }
    .discuss-item:nth-child(2) {
        width: 80%;
        left: 50px;
    } 
    .discuss-item:last-child {
        width: 80%;
        left: 50px;
    }
}
/* ExtraSmall4: 380px - 230px */
@media (max-width: 380px) {
	.container {
		padding: 0 15px;
        max-width: 100%;
	}  
    .offer-title{
        font-size: 40px;
    }
    .offer-text {
        top: 320px;
    }
    div.ne {
        width: 83px;
        margin-right: 0px;
    }
    .discuss-item:last-child {
        width: 70%;
        word-wrap: break-word;
    }
    .zoom-video>video{
        margin-top: -110px;
    }
    .offer-btn{
        margin-top: -50px;
        font-size: 16px;
    }
    .discuss-btn{
        font-size: 16px;
    }
    .social-wrapper{
        bottom: 0;
    }
}


/* ExtraSmall5: 380px - 230px */
/* @media (max-width: 380px) {
    .offer-btn{
        font-size: 16px;
    }

} */