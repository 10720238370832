.section-notFound{
    .container{
        .section-title-wrapper{
            .arrow-home{
                top: 60px;
            }
            padding-top: 0;
            padding-bottom: 150px;
        }
        .notFound-text{
            font-size: 340px;
            font-weight: 700;
            text-align: center;
            .logo{
                background-image: url('../../components/image/favicon404.png');
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center center;
                height: 310px;
                width: 208px;
                display: inline-block;
                margin: 0 35px;
            }
        }
        .notFound-desc{
            text-align: center;
            font-size: 24px;
            margin-top: 70px;
        }
    }
    margin-bottom: 0px;
    footer.footer{
        margin-top: 50px;
    }
}

