/* .header{
    padding: 0;
    margin: 0;
} */
section.about-main{
    padding-top: 150px;
}
.slider-text-wrapper{
    position: relative; 

}
.about-title-wrapper{
    margin-bottom: 0;
    position: relative;
}
.about-title-wrapper_main{
    margin-bottom: 60px;
}
.about-title{
    margin-bottom: 120px;
}
.about-title_main{
    margin-bottom: 0;
}

.about-slider-wrapper{
    height: 962px;
    width: 1081px;
    position: relative;
    margin: 0 auto;
    padding-left: 188px;
    display: flex;
    align-items: center;
}
.about-slider-wrapper::before{
    content: '';
    background: url('../../components/image/about-slider.svg') no-repeat center;
    height: 1050px;
    width: 1200px;
    position: absolute;
    left: -80px;
    top: 0;
}
.about-slide{
    /* display: none; */
    padding-left: 74px;
    position: relative;
}
.about-slide.active{
    display: block;
}
.slide-title{
    position: relative;
    margin-bottom: 30px;
    word-wrap: break-word;
    width: 100%;
}
.slide-text{
    width: 704px;
    line-height: 1.1em;
}
.slider-star{
    position: absolute;
    top: 12px;
    left: -50px;
}
/* .about-slide:first-child > .slide-title{
    width: 453px;
} */
.slider-star-logo{
    background: url(' ../../components/image/star1.svg');
    width: 44px;
    height: 35px;
}
/* .about-slide:nth-child(2) > .slide-title{
    width: 319px;
} */
.slider-star-site{
    background: url(' ../../components/image/star2.svg');
    width: 36px;
    height: 46px;
}
/* .about-slide:nth-child(3) > .slide-title{
    width: 781px;
} */
.slider-star-brand{
    background: url(' ../../components/image/star2.svg');
    width: 36px;
    height: 46px;
}
/* .about-slide:nth-child(4) > .slide-title{
    width: 700px;
} */
.slider-star-motion{
    background: url(' ../../components/image/star3.svg');
    width: 39px;
    height: 47px;
}
/* .about-slide:nth-child(5) > .slide-title{
    width: 484px;
} */
.slider-star-marketing{
    background: url(' ../../components/image/star3.svg');
    width: 39px;
    height: 47px;
}
.arrow-next, .arrow-prev{
    background-image: url("../../components/image/arrow.svg");
    transition: bacground-image .5s ease-in-out;
    width: 52px;
    height: 52px;
    position: absolute;
    opacity: 1;
    transition: opacity .5s ease-in-out;
}
.arrow-next{
    bottom: 300px;
    right: 140px;
    transform: rotate(180deg);
}
.arrow-prev{
    left: 136px;
    top: 252px;
}



section.work{
    margin-bottom: 350px;
}
.work-title{
    margin-bottom: 60px;
    position: relative;
}
.work-title::after{
    content: '';
    position: absolute;
    background: url(' ../../components/image/about-page1.svg') no-repeat center;
    width: 450px;
    height: 350px;
    right: 0;
    top: -20px;

}
.about-item{
    font-size: 30px;
    line-height: 108%;
}
.about-item{
    width: 470px;
    text-transform: none;
    margin-bottom: 35px;
}
.about-item_main{
    width: auto;
}
.about-item_main {
    text-transform: uppercase;
    font-size: 48px;
    color: #f1f1f1;
    letter-spacing: -0.02em;
    position: relative;
    margin-bottom: 60px;
}
.about-item-wrapper:first-child::after{
    background: url(' ../../components/image/about-page2.svg') no-repeat center;
    width: 400px;
    height: 290px;
    left: -130px;
    top: 320px;
}
.about-item-wrapper_main:first-child::after {
    content: '';
    position: absolute;
    background: url(' ../../components/image/arrow-pic.svg') no-repeat center;
    background-size: contain;
    width: 246px;
    height: 287px;
    left: 66px;
    bottom: -160px;
}
.about-item-wrapper:first-child{
    width: auto;
}
.about-item-wrapper:nth-child(2){
    padding-top: 160px;
    width: auto;
}
.about-item-wrapper:last-child{
    padding-top: 290px;
    width: auto;
}
.about-item-wrapper:first-child > .about-item {
    padding-left: 60px;
}
.about-item-wrapper:nth-child(2) > .about-item {
    padding-left: 60px;
}
.about-item-wrapper:last-child > .about-item {
    padding-left: 60px;
}

.about-item-wrapper_main:first-child > .about-item {
    padding-left: 74px;
}


section.proccess{
    margin-bottom: 300px;
}
.proccess-title{
    margin-bottom: 120px;
}
.proccess-wrapper{
    width: 967px;
    margin: 0 auto;
}
.proccess-item{
    padding-left: 250px;
    margin-bottom: 62px;
    position: relative;
}
.proccess-item:before{
    content: '';
    position: absolute;
    top: 10px;
    left: 50px;
    width: 30px;
    height: 60px;
    background-size: contain;
}
.proccess-item:first-child:before{
    background: url(' ../../components/image/one.svg') no-repeat center;
    background-size: contain;
}
.proccess-item:nth-child(2):before{
    background: url(' ../../components/image/two.svg') no-repeat center;
    background-size: contain;
}
.proccess-item:nth-child(3):before{
    background: url(' ../../components/image/three.svg') no-repeat center;
    background-size: contain;
    top: -5px;
    width: 40px;
}
.proccess-item:nth-child(4):before{
    background: url(' ../../components/image/four.svg') no-repeat center;
    background-size: contain;
    width: 35px;
    height: 65px;
}
.proccess-item:last-child:before{
    content: '';
    position: absolute;
    background: url(' ../../components/image/five.svg') no-repeat center;
    background-size: contain;
    top: -15px;
    left: 0;
    width: 130px;
    height: 125px;
}

.team-title{
    margin-bottom: 60px;
}
.team-desc{
    width: 560px;
    margin-bottom: 75px;
}
.team-gallery{
    width: 100%;
    position: relative;
}
.team-background{
    background: url(" ../../components/image/team-bg.svg") no-repeat center;
    background-size: contain;
    width: 100%;
    height: 1110px;
    z-index: -3;
    margin: -150px auto 0;
} 
.gallery-item-big{
    position: absolute;
    width: 250px;
    height: 250px;
    border-radius: 250px;
    z-index: 1;
    transition: transform 1s ease-in-out; 
}
.gallery-item-small{
    position: absolute;
    width: 70px;
    height: 70px;
    border-radius: 70px;
    z-index: 1;
    transition: transform 1s ease-in-out; 
}
.gallery-item-big:first-child{
    top: 0px;
    left: 0px;
}
.gallery-item-big:nth-child(2){
    top: 375px;
    left: 174px;
}
.gallery-item-big:nth-child(3){
    top: 478px;
    left: 461px;
}
.gallery-item-big:nth-child(4){
    top: 180px;
    left: 595px;
}
.gallery-item-big:nth-child(5){
    top: 375px;
    right: 170px;
}
.gallery-item-big:nth-child(6){
    top: -100px;
    right: 219px;
}
/* .gallery-item-big:nth-child(7){
    top: 0px;
    left: 1002px;
}
.gallery-item-big:nth-child(8){
    top: 325px;
    left: 1067px;
} */

.gallery-item-small:first-child{
    top: 154px;
    left: 290px;
}
.gallery-item-small:nth-child(2){
    top: 704px;
    left: 340px;
}
.gallery-item-small:nth-child(3){
    top: 0px;
    left: 600px;
}
.gallery-item-small:nth-child(4){
    top: 445px;
    right: 501px;
}
.gallery-item-small:nth-child(5){
    top: 225px;
    right: 110px;
}
/* .gallery-item-small:nth-child(6){
    top: 228px;
    right: 0;
} */

.team-button{
    width: 400px;
    height: 400px;
    border-radius: 400px;
    border: 5px solid #01EB81;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 650px;
    right: 295px;
    text-align: center;
}
.team-button-text{
    margin-bottom: 25px;
    font-size: 20px;
}
.team-button-cta{
    font-size: 32px;
    text-transform: uppercase;
    color: #01EB81;
}
.team-plug-wrapper{
  display: none;  
}
.team-plug-wrapper.active{
    display: block;
}
.team-plug{ 
    z-index: 1000;
    position: fixed;
    width: 500px;
    height: 400px;
    background: #01EB81;
    border-radius: 40px;
    margin-left: -250px;
    margin-top: -200px;
    top: 50%;
    left: 50%;
    color: #131313;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.plug-text{
    width: 80%;
}
.overlay{
    position:fixed;
    z-index: 999;
    top:0;
    right:0;
    left:0;
    bottom:0;
    background:#000;
    opacity:0.6;
}

.gallery-popup-wrapper{
    display: none;
    position: relative;
    transition: visibility .5s ease-in-out;
}
.gallery-popup-wrapper.active{
    display: block;
}
.gallery-overlay{
    position:fixed;
    z-index: 999;
    top:0;
    right:0;
    left:0;
    bottom:0;
    background:#131313;
    opacity:0.5;
}
.gallery-popup{
    width: 685px;
    height: 685px;
    border-radius: 685px;
    position: fixed;
    margin-top: -342.5px;
    top: 50%;
    left: 50%;
    margin-left: -342.5px;    
    background-color: #f1f1f1; 
    z-index: 1000; 
    background-image: url(' ../../components/image/person-bg.svg');
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
}

.person-portrait{
    width: 250px;
    height: 250px;
    border-radius: 250px;
    margin-right: 60px;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 2000;
    background-color: #131313;
}
.person-info{
    color: #131313;
}
.person-name{
    width: 202px;
    font-size: 30px;
    margin-bottom: 28px;
}
.person-position{
    font-size: 20px;
    width: 250px;
}



/*  RESPONSIVE  */

/* * ExtraLarge: 1600px - 2000px */ 
@media (max-width: 2000px){
    .container {
		max-width: 1440px;
	}
    .about-item-wrapper:first-child {
        width: auto;
    }
    .about-item-wrapper:nth-child(2) {
        width: auto;
    }
    .about-item-wrapper:last-child {
        width: auto;
    }
    /* .about-item-wrapper_main:nth-child(2) {
        width: 531px;
        padding-top: 100px;
    }
    .about-item-wrapper_main:last-child {
        width: 336px;
        padding-top: 200px;
    } */
}
/* * ExtraLarge2: 1490px - 1600px мой ноут */
@media (max-width: 1600px){
    .container {
		max-width: 1280px;
	}  
    .about-slider-wrapper{
        width: 100%;
    }
    .about-slide{
        padding-left: 150px;
    }
    .about-slider-wrapper::before {
        height: 1020px;
        width: 1200px; 
        left: 50px;  
    }
    .arrow-prev {
        left: 190px;
        top: 252px;
    }
    .arrow-next {
        bottom: 300px;
        right: 190px;
    }
    .about-wrapper{
        display: block;        
    }
    .about-item-wrapper:first-child {
        width: auto;
        margin-right: 200px;
    }
    .about-item-wrapper:nth-child(2){
        padding-top: 0;
        margin-left: 50%;
        margin-top: -150px;
    }
    .about-item-wrapper:last-child {
        padding-top: 0px;
        margin-left: 50%;
    }
    .work-title::after{
        top: -50px;
    }
    .team-background{
        width: 80%;
    }
    .small-wrapper{
        position: relative;
    }
    .gallery-item-big:first-child{
        top: 20px;
        left: 0px;
    }
    .gallery-item-big:nth-child(2){
        top: 375px;
        left: 150px;
    }
    .gallery-item-big:nth-child(3){
        top: 478px;
        left: 400px;
    }
    .gallery-item-big:nth-child(4){
        top: 180px;
        left: 480px;
    }
    .gallery-item-big:nth-child(5){
        top: 375px;
        right: 170px;
    }
    .gallery-item-big:nth-child(6){
        top: -100px;
        right: 219px;
    }
    /* .gallery-item-big:nth-child(7){
        top: 0px;
        left: 1002px;
    }
    .gallery-item-big:nth-child(8){
        top: 325px;
        left: 1067px;
    } */
    
    .gallery-item-small:first-child{
        top: 154px;
        left: 290px;
    }
    .gallery-item-small:nth-child(2){
        top: 704px;
        left: 300px;
    }
    .gallery-item-small:nth-child(3){
        top: 20px;
        left: 520px;
    }
    .gallery-item-small:nth-child(4){
        top: 445px;
        right: 501px;
    }
    .gallery-item-small:nth-child(5){
        top: 225px;
        right: 110px;
    }

}
/* * ExtraLarge3: 1199px - 1490px */ 
@media (max-width: 1490px){
    .container {
		max-width: 1110px;
	}
    .about-slide{
        padding-left: 150px;
    }
    .about-item {
        font-size: 24px;
    }
    .about-item_main {
        font-size: 34px;
    }
    .about-slider-wrapper{
        width: 100%;
    }
    .about-slider-wrapper::before {
        transform: scale(0.85);
        left: 0;
    }
    .about-wrapper{
        justify-content: start;
    }
    .proccess-wrapper{
        width: 100%;
    }
    .slide-text {
        width: 80%;
    }
    .arrow-prev {
        left: 250px;
        top: 300px;
    }
    .work-title::after {
        top: -100px;
        transform: scale(0.7);
    }
    .about-item-wrapper:first-child::after{
        transform: scale(0.7);
        left: -50px;
        top: 200px;

    }
    .about-item-wrapper_main:first-child::after{
        transform: scale(1);
    }
    .team-background{
        width: 85%;
    }
    .gallery-item-big{
        width: 210px;
        height: 210px;
    }
    .gallery-item-big:first-child{
        top: 80px;
        left: 0;
    }
    .gallery-item-big:nth-child(2){
        top: 375px;
        left: 135px;
    }
    .gallery-item-big:nth-child(3){
        top: 478px;
        left: 340px;
    }
    .gallery-item-big:nth-child(4){
        top: 180px;
        left: 400px;
    }
    .gallery-item-big:nth-child(5){
        top: 375px;
        right: 170px;
    }
    .gallery-item-big:nth-child(6){
        top: 50px;
        right: 200px;
    }    
    .gallery-item-small:first-child{
        top: 250px;
        left: 220px;
    }
    .gallery-item-small:nth-child(2){
        top: 620px;
        left: 250px;
    }
    .gallery-item-small:nth-child(3){
        top: 50px;
        left: 450px;
    }
    .gallery-item-small:nth-child(4){
        top: 400px;
        right: 420px;
    }
    .gallery-item-small:nth-child(5){
        top: 225px;
        right: 80px;
    }
    .team-button{
        top: 700px;
    }
    .proccess-item:before {
        top: 0px;
        left: 100px;
        transform: scale(0.8);
    }
    .proccess-item:last-child:before {
        top: -30px;
        left: 50px;
        transform: scale(0.8);
    }
    .about-item-wrapper:first-child > .about-item {
    padding-left: 40px;
    }
    .about-item-wrapper:nth-child(2) > .about-item {
        padding-left: 40px;
    }
    .about-item-wrapper:last-child > .about-item {
        padding-left: 40px;
    }
    .slide-title{
        width: 90%;
    }

}

/* * Large: 992px - 1199px */ 
@media (max-width: 1199px){
    .container {
		max-width: 960px;
	}
    .about-slider-wrapper::before {
        transform: scale(0.7);
        left: -80px;
    }
    .slide-title{
        width: 80%;
        margin-right: 0;
    }
    .slide-text{
        width: 70%;
    }
    .proccess-item {
        padding-left: 150px;
    }
    .proccess-item:before {
        top: 0px;
        left: 50px;
        transform: scale(0.8);
    }
    .proccess-item:last-child:before {
        top: -25px;
        left: 0;
        width: 130px;
        height: 125px;
    }
    .gallery-item-big{
        width: 180px;
        height: 180px;
    }
    .gallery-item-big:first-child{
        top: 120px;
        left: 0;
    }
    .gallery-item-big:nth-child(2){
        top: 400px;
        left: 100px;
    }
    .gallery-item-big:nth-child(3){
        top: 478px;
        left: 280px;
    }
    .gallery-item-big:nth-child(4){
        top: 220px;
        left: 350px;
    }
    .gallery-item-big:nth-child(5){
        top: 390px;
        right: 170px;
    }
    .gallery-item-big:nth-child(6){
        top: 100px;
        right: 190px;
    }    
    .gallery-item-small:first-child{
        top: 260px;
        left: 200px;
    }
    .gallery-item-small:nth-child(2){
        top: 650px;
        left: 200px;
    }
    .gallery-item-small:nth-child(3){
        top: 130px;
        left: 380px;
    }
    .gallery-item-small:nth-child(4){
        top: 425px;
        right: 380px;
    }
    .gallery-item-small:nth-child(5){
        top: 250px;
        right: 50px;
    }
    .about-item-wrapper_main:first-child::after {
        left: 50px;
        top: 200px;
    }
    .team-button{
        right: 200px;
    }
}

/* Medium: 768px - 991px */
@media (max-width: 991px) {
	.container {
        width: 100%;
		max-width: 720px;
	}
    section{
        margin-bottom: 190px;
    }
    .about-slider-wrapper::before {
        transform: scale(0.6);
        left: -220px;
    }
    .about-slider-wrapper{
        padding-left: 40px;
        margin-top: -300px;
    }
    .slide-title{
        width: 90%;
    }
    .slide-text {
        width: 80%;
    }
    .arrow-next {
        bottom: 480px;
        right: 80px;
    }
    .arrow-prev {
        left: 140px;
        top: 300px;
    }
    .about-item{
        width: 360px;
    }
    .about-item_main{
        width: auto;
    }
    .about-item-wrapper_main:first-child > .about-item{
        padding-left: 66px;
    }
    .about-item-wrapper_main:nth-child(2) > .about-item{
        padding-left: 66px;
    }
    .about-item-wrapper_main:last-child > .about-item{
        padding-left: 66px;
    }
    .about-item-wrapper_main:nth-child(2) {
        padding-top: 0;
        margin-left: 40%;
        margin-top: -50px;
    }
    .about-item-wrapper_main:last-child {
        padding-top: 0;
        margin-left: 40%;
    }
    .about-postscr{
        width: auto;
    }
    .work-title::after {
        background-size: contain;
        width: 300px;
        top: -80px;
    }
    .about-item-wrapper:first-child::after {
        background-size: contain;
        width: 250px;
        top: 380px;
        left: 20px;
    }
    .about-item-wrapper_main:first-child::after {
        background-size: contain;
        width: 179px;
        height: 209px;
        bottom: -300px;
    }
    .about-title-wrapper_main{
        position: inherit;
    }
    .about-item-wrapper_main:first-child::after{
        top: 200px;
    }

    .team-background {
        background: url("../../components/image/team-bg-2.svg") no-repeat center;
        background-size: contain;
        width: 100%;
        height: 1150px;
        z-index: -3;
        margin: 0;
        /* margin: -260px auto 0; */
    }
    .team-button {
        top: 1200px;
        left: auto;
        right: 20px;
    }
    .team-desc{
        margin-bottom: 180px;
    }

    section.team{
        margin-bottom: 550px;

    }
    .gallery-item-big:first-child{
        top: 120px;
        left: 0;
    }
    .gallery-item-big:nth-child(2){
        top: 350px;
        left: 180px;
    }
    .gallery-item-big:nth-child(3){
        top: 650px;
        left: 380px;
    }
    .gallery-item-big:nth-child(4){
        top: 850px;
        left: auto;
        right: 20px;
    }
    .gallery-item-big:nth-child(5){
        top: 250px;
        right: 10px;
    }
    .gallery-item-big:nth-child(6){
        top: 1050px;
        right: auto;
        left: 100px;
    }    
    .gallery-item-small:first-child{
        top: 600px;
        left: 200px;
    }
    .gallery-item-small:nth-child(2){
        top: 500px;
        left: 420px;
    }
    .gallery-item-small:nth-child(3){
        top: 50px;
        left: 450px;
    }
    .gallery-item-small:nth-child(4){
        top: 250px;
        right: 300px;
    }
    .gallery-item-small:nth-child(5){
        top: 880px;
        right: auto;
        left: 200px;
    }

    section.discuss{
        margin-bottom: -150px;
    }
    .about-slide {
        padding-left: 130px;
    }
    .slide-title {
        margin-top: 20px;
    }
    section{
        margin-bottom: 50px;
    }
    section.proccess{
         margin-bottom: 200px;
    }
    .proccess-item:before {
        left: 50px;
    }

}

/* Small: 576px - 767px */
@media (max-width: 767px) {
	.container {
		max-width: 540px;
	}
    .about-slider-wrapper {
        padding-left: 0px;
    }
    .about-slider-wrapper::before {
        transform: scale(0.48);
        left: -330px;
        height: 1010px;
    }
    .slider-star{
        top: 0px;
    }
    .about-slide {
        padding-left: 90px;
    }
    .about-slide>.slide-title::before {
        top: -45px;
        left: 150px;
    }
    .slide-title {
        width: 100%;
        margin-bottom: 15px;
    }
    .arrow-prev {
        left: 140px;
        top: auto;
        bottom: 300px;
    }
    .arrow-next{
        bottom: 300px;
        right: auto;
        left: 200px;

    }
    .about-item {
        font-size: 20px;
        width: 100%;
        max-width: 360px;
    }
    .about-item_main {
        font-size: 28px;
    }
    section.about-main{
        margin-bottom: -50px;
    }
    .work-title {
        margin-bottom: 50px;
    }
    .work-title::after {
        top: -100px;
        right: -50px;
    }
    .about-item-wrapper:first-child::after {
        background-size: contain;
        top: 250px;
        left: -50px;
    }
    .about-item-wrapper:nth-child(2) {
        padding-top: 150px;
        margin-left: 50%;
    }
    .about-item-wrapper:last-child {
        margin-left: 50%;
    }
    .about-item-wrapper:first-child{
        margin-right: 0;
    }
    .about-item_main{
        margin-bottom: 40px;
    }
    .about-item-wrapper_main:nth-child(2) {
        margin-left: 35%;
        padding-top: 50px;
    }
    .about-item-wrapper_main:last-child {
        margin-left: 35%;
    }
    .about-item-wrapper_main:first-child::after {
        top: 180px;
        left: 0px;
    }
    section.work{
        margin-bottom: 200px;
    }
    .proccess-title {
        margin-bottom: 50px;
    }
    .proccess-item:before{
        top: 10px;
        left: 20px;
        width: 25px;
        background-size: contain;
    }
    .proccess-item:last-child:before {
        top: -35px;
        left: 0;
        width: 80px;
        background-size: contain;
    }
    .proccess-item {
        padding-left: 100px;
    }
    .proccess-item:nth-child(3):before{
        top: -5px;
        width: 25px;
    }
    .proccess-item:first-child:before{
        top: -5px;
    }
    .proccess-item:nth-child(4):before{
        width: 25px;
        top: -5px;
    }
    section.proccess {
        margin-bottom: 190px;
    }
    .team-background {
        background: url(" ../../components/image/team-bg-3.svg") no-repeat center;
        height: 840px;
        width: 100%;
    }
    section.team{
        margin-bottom: 680px;
    }
    .gallery-item-big{
        width: 150px;
        height: 150px;
    }
    .gallery-item-big:first-child{
        top: -20px;
        left: 100px;
    }
    .gallery-item-big:nth-child(2){
        top: 280px;
        left: 200px;
    }
    .gallery-item-big:nth-child(3){
        top: 520px;
        left: 150px;
    }
    .gallery-item-big:nth-child(4){
        top: 550px;
        left: auto;
        right: 50px;
    }
    .gallery-item-big:nth-child(5){
        top: 150px;
        right: 0px;
    }
    .gallery-item-big:nth-child(6){
        top: 760px;
        right: auto;
        left: 90px;
    }    
    .gallery-item-small:first-child{
        top: 450px;
        left: 100px;
    }
    .gallery-item-small:nth-child(2){
        top: 400px;
        left: 395px;
    }
    .gallery-item-small:nth-child(3){
        top: -20px;
        left: 350px;
    }
    .gallery-item-small:nth-child(4){
        top: 180px;
        right: 200px;
    }
    .gallery-item-small:nth-child(5){
        top: 670px;
        right: auto;
        left: 80px;
    }
    .team-desc{
        width: 100%;
    }
    .gallery-popup{
        width: 100%;
        left: 50%;
        top: 50%;
        margin-top: -50%;
        margin-left: -50%;
        aspect-ratio: 1;
        height: auto;
    }
    .person-portrait {
        width: 150px;
        height: 150px;
    }
    .team-button{
        top: 900px;
    }
 

}

/* ExtraSmall1: 576px - 500px */
@media (max-width: 575px) {
	.container {
        max-width: 490px;
	}  
    h3{
        font-size: 32px;
    }
    .slide-title {
        font-size: 24px;
    }
    .about-slider-wrapper::before{
        background: url(" ../../components/image/about-slider2.svg") no-repeat left;
        background-size: 100%;
        transform: scale(1.1);
        width: 90%;
        left: 5%;
    }
    .about-slide{
        padding-left: 100px;
    }
    .slider-star{
        transform: scale(0.6);
        top: -10px;
    }
    .about-slide>.slide-title::before {
        top: -45px;
        left: 25%;
        transform: scale(0.5);
    }
    .arrow-prev{
        left: 80px;
        bottom: 320px;
    }
    .arrow-next{
        left: 140px;
        bottom: 320px;
    }
    .work-title::after {
        display: none;
    }
    .about-item-wrapper:first-child{
        margin-right: 0;
    }
    .about-item-wrapper:first-child::after {
        display: none;
    }
    .about-item-wrapper:nth-child(2) {
        margin-left: 0px;
    }
    .about-item {
        font-size: 16px;
    }
    .about-item_main {
        font-size: 20px;
        margin-bottom: 20px;
    }
    .about-item-wrapper_main:first-child > .about-item {
        padding-left: 30px;
    }
    .about-item-wrapper_main:nth-child(2) > .about-item {
        padding-left: 30px;
    }
    .about-item-wrapper_main:last-child > .about-item {
        padding-left: 30px;
    }
    .about-item-wrapper:last-child {
        margin-left: 0px;
    }
    .about-item-wrapper:nth-child(2) {
        margin-left: 20%;
    }
    .about-item-wrapper:last-child {
        margin-left: 20%;
    }
    .about-item-wrapper_main:first-child::after {
        display: block;
        background-size: contain;
        width: 115px;
        height: 134px;
        left: 0px;
        top: 150px;
    }
    section.work {
        margin-bottom: 150px;
    }
    section.about-main {
        margin-bottom: -100px;
    }
    .team-desc {
        width: 100%;
        margin-bottom: 120px;
    }
    .team-button {
        top: 1100px;
        left: 50%;
        margin-left: -195px;
    }
    section.about-main {
        padding-top: 95px;
    }
    .team-plug{
        width: 80%;
        margin-left: -40%;
    }
    .team-background {
        background: url(" ../../components/image/team-bg-4.svg") no-repeat center;
        height: 990px;
        background-position: 20px 0;
    }
    section.team{
        margin-bottom: 680px;
    }
    .gallery-item-big{
        width: 150px;
        height: 150px;
    }
    .gallery-item-small{
        width: 50px;
        height: 50px;
    }
    .gallery-item-big:first-child{
        top: -30px;
        left: 20px;
    }
    .gallery-item-big:nth-child(2){
        top: 550px;
        left: 80px;
    }
    .gallery-item-big:nth-child(3){
        top: 300px;
        left: 50px;
    }
    .gallery-item-big:nth-child(4){
        top: 800px;
        left: auto;
        right: 150px;
    }
    .gallery-item-big:nth-child(5){
        top: 400px;
        right: 160px;
    }
    .gallery-item-big:nth-child(6){
        top: 900px;
        right: auto;
        left: 20px;
    }    
    .gallery-item-small:first-child{
        top: 200px;
        left: 100px;
    }
    .gallery-item-small:nth-child(2){
        top: 650px;
        left: 250px;
    }
    .gallery-item-small:nth-child(3){
        top: 100px;
        left: 220px;
    }
    .gallery-item-small:nth-child(4){
        top: 300px;
        right: 220px;
    }
    .gallery-item-small:nth-child(5){
        top: 750px;
        right: auto;
        left: 80px;
    }
    .person-portrait {
        width: 150px;
        height: 150px;
        margin-right: 20px;
    }
    .person-name{
        font-size: 24px;
    }
    .person-position{
        font-size: 16px;
    }

}

/* ExtraSmall2: 500px - 400px */
@media (max-width: 500px) {
	.container {
		max-width: 390px;
	} 
    .about-slide{
        padding-left: 60px;
    } 
    .slider-star{
        top: -40px;
        left: 20%;
    }
    .person-portrait {
        width: 100px;
        height: 100px;
        margin-right: 20px;
    }
    .proccess-item{
        padding-left: 80px;
    }
    .team-button {
        width: 100%;
        height: auto;
        aspect-ratio: 1;
        left: 0;
        margin-left: 0;
    }
    .about-slider-wrapper {
        margin-top: -380px;
    }
    section.about-main {
        margin-bottom: -200px;
    }
    section.work {
        margin-bottom: 100px;
    }
    section.proccess {
        margin-bottom: 120px;
    }
    section.team {
        margin-bottom: 600px;
    }
    
    footer.footer {
        margin-top: -350px;
    }

    .gallery-item-big:first-child{
        top: -30px;
        left: 20px;
    }
    .gallery-item-big:nth-child(2){
        top: 550px;
        left: 80px;
    }
    .gallery-item-big:nth-child(3){
        top: 300px;
        left: 50px;
    }
    .gallery-item-big:nth-child(4){
        top: 800px;
        left: auto;
        right: 50px;
    }
    .gallery-item-big:nth-child(5){
        top: 400px;
        right: 50px;
    }
    .gallery-item-big:nth-child(6){
        top: 900px;
        right: auto;
        left: 20px;
    }    
    .gallery-item-small:first-child{
        top: 200px;
        left: 100px;
    }
    .gallery-item-small:nth-child(2){
        top: 650px;
        left: 250px;
    }
    .gallery-item-small:nth-child(3){
        top: 100px;
        left: 220px;
    }
    .gallery-item-small:nth-child(4){
        top: 300px;
        right: 120px;
    }
    .gallery-item-small:nth-child(5){
        top: 750px;
        right: auto;
        left: 80px;
    }
}

/* ExtraSmall3: 400px - 380px */
@media (max-width: 400px) {
	.container {
		padding: 0 15px;
        max-width: 100%;
	} 
    .person-name{
        font-size: 18px;
    }
    .person-position{
        font-size: 14px;
    }
    .about-item-wrapper:nth-child(2) {
        left: 0;
        margin-left: 0;
    }
    .about-item-wrapper:last-child{
        left: 0;
        margin-left: 0;
    }
    .about-item-wrapper_main:nth-child(2) {
        margin-left: 20%;
    }
    .about-item-wrapper_main:last-child{
        margin-left: 20%;
    }
    .gallery-item-big{
        width: 120px;
        height: 120px;
    }
    .gallery-item-small{
        width: 40px;
        height: 40px;
    }

    .arrow-prev{
        left: 80px;

    }
    .arrow-next{
        left: 140px;

    }
    
    .gallery-item-big:first-child{
        top: -30px;
        left: 20px;
    }
    .gallery-item-big:nth-child(2){
        top: 550px;
        left: 80px;
    }
    .gallery-item-big:nth-child(3){
        top: 300px;
        left: 50px;
    }
    .gallery-item-big:nth-child(4){
        top: 800px;
        left: auto;
        right: 30px;
    }
    .gallery-item-big:nth-child(5){
        top: 400px;
        right: 40px;
    }
    .gallery-item-big:nth-child(6){
        top: 900px;
        right: auto;
        left: 20px;
    }    
    .gallery-item-small:first-child{
        top: 200px;
        left: 100px;
    }
    .gallery-item-small:nth-child(2){
        top: 650px;
        left: 250px;
    }
    .gallery-item-small:nth-child(3){
        top: 100px;
        left: 220px;
    }
    .gallery-item-small:nth-child(4){
        top: 300px;
        right: 90px;
    }
    .gallery-item-small:nth-child(5){
        top: 750px;
        right: auto;
        left: 80px;
    }
}

/* ExtraSmall4: 380px - 230px */
@media (max-width: 380px) {
	.container {
		padding: 0 15px;
        max-width: 100%;
	}  
    .person-position{
        width: auto;
    }
    .gallery-item-big:nth-child(4){
        top: 800px;
        left: 180px;
        right: auto;
    }
    .gallery-item-big:nth-child(5){
        top: 400px;
        right: auto;
        left: 160px;
    }
    .gallery-item-small:nth-child(4){
        top: 300px;
        right: auto;
        left: 200px;
    }
    .gallery-item-small:nth-child(2){
        top: 650px;
        left: 220px;
    }

    .proccess-title{
        word-wrap: break-word;
    }
    .about-item{
        width: 100%;
    }
    .arrow-prev{
        left: 60px;
        transform: scale(0.8);

    }
    .arrow-next{
        left: 110px;
        transform: scale(0.8) rotate(180deg);
    }
    .person-portrait{
        width: 80px;
        height: 80px;
    }
    .team-button-text{
        font-size: 20px;
    }
    .team-button-cta {
        font-size: 25px;
    }


}