section.privacy{
    padding-top: 200px;
}
.privacy-main-title-wrapper{
    position: relative;
}
.privacy-main-title{
    margin-bottom: 100px;
    word-wrap: break-word;
}
.privacy-title{
    margin-bottom: 25px;
    margin-top: 50px;
    word-wrap: break-word;
}
.privacy>.container>p{
    margin-bottom: 10px;
}

@media (max-width: 575px) {
    section.privacy{
        padding-top: 95px;
    }

} 
