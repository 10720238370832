/* .header{
    margin-bottom: 100px;
} */

.price-head{
    padding-top: 150px;
}
.price-header-subtitle{
    margin-top: 15px;
}
.price-title-wrapper{
    position: relative;
}
/* .bg-price{
    background: url("../../components/image/bg-price.svg" ) no-repeat center;
    position: absolute;
    right: 0%;
    top: -56.38%;
    bottom: 53.61%;
    width: 100%;
    height: 1950px;
    z-index: -10;  
} */
ol{
    padding: 0;
    margin: 0;
}
.condition-header-title{
    margin-top: 150px;
}
select.currency-select{
    width: 300px;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    background-color: transparent;
    border: 1px solid #01EB81;
    color: #01EB81;
    border-radius: 12px;
    outline: none;
    /* cursor: none; */
    font-size: 24px;
    font-family: "CeraPro";
    margin-top: 50px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
.currency-select::-ms-expand{
    display: none;
}
.currency-select-wrapper{
    display: inline;
}
.currency-select{
    width: 170px;  
    appearance: none; 
    overflow: hidden;
    background: url('../../components/image/select-arrow-green.svg') no-repeat 70%;
}

.currency-select > option{
    background-color: #131313;
    border: 1px solid #01EB81;
    border-radius: 20px;
    text-align: center;
    color: #01EB81;
    font-size: 24px;
    margin-bottom: 20px;
}
.price-summary-rules{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap
}
.price-summary-title{
    margin-bottom: 50px;
}
.price-summary-subtitle{
    margin-bottom: 30px;
}
.rules-column{
    width: 45%;
}
ol.rules{
    margin-top: 40px;
}
ul.rules{
    margin-top: 40px;
}
ol.rules-num{
    counter-reset: myCounter;
  }
  
li.rule-num{
    list-style: none;
}

li.rule-num:before {
    counter-increment: myCounter;
    content:counter(myCounter);
    color: #01EB81;
    display: inline-block;
    text-align: center;
    line-height: 1.2em;
    margin-right: 20px;
}
.rules-column-title{
    position: relative;
    padding-left: 80px;
}
.rules-column-title:before{
    content: '';
    position: absolute;
    background-size: 100%;
    background-repeat: no-repeat;
    top: -5px;
    left: 0px;
}
.rules-column-title-one:before{
    width: 30px;
    height: 51px;
    background-image: url('../../components/image/1.svg');
}
.rules-column-title-two:before{
    width: 30px;
    height: 50px;
    background-image: url('../../components/image/2.svg');
}
li.rule:not(:last-child){
    margin-bottom: 30px;
}
li.rule-num:not(:last-child){
    margin-bottom: 0;
}
.branding-tarifs{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.branding-item{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 470px;
    height: 700px;
    border: 1px solid #f1f1f1;
    padding: 28px 20px 28px 25px;
    position: relative;
}
.tarif-mark{
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 20px;
}
.branding-item::after{
    content: '';
    position: absolute;
    width: 100px;
    height: 94px;
    background: url('../../components/image/tarif-mark.svg') no-repeat center;
    background-size: contain;
    top: -25px;
    right: 0;
}
.branding-item{
    margin-bottom: 30px;
}

ul.tarif-desc{
    font-size: 16px;
    padding-left: 25px;
}
.tarif-subdesc{
    text-align: center;
}
.tarif-time{
    font-size: 20px;
}
.tarif-value{
    color: #01EB81;
}
.tarif-price{
    color: #01EB81;
    margin-top: 15px;
}

.tarif-title{
    margin-bottom: 44px;
}
.tarif-desc-item{
    position: relative;
    text-indent: -25px;
}
.tarif-desc-item:not(:last-child){
    margin-bottom: 5px;
}
.tarif-desc-item::before{
    content: '—';
    color: #01EB81;
    margin-right: 10px;
}
ul.tarif-desc > li.tarif-desc-item > ul{
     padding-left: 30px;
}
.section-title{
     margin-bottom: 50px;
}


.additional-services-wrap{
    width: 90%;
}
.service-title-wrap{
    display: flex;
    justify-content: space-between;
}
.service-item{
    display: flex;
    justify-content: space-between;
    position: relative;
}
.service::before {
    content: '—';
    color: #01EB81;
    margin-right: 10px;
}
.service-item{
    margin-top: 20px;
    font-size: 16px;
}
.price-value{
    font-size: 16px;
}
.service-price-item{
    position: relative;
    display: flex;
    align-items: end;
}
.service-price-line{
    position: absolute;
    height: 1px;
    background: #01EB81;
    right: 80px;
    top: 15px;
}
.additional-service-subdesc{
    width: 50%;
    margin-top: 100px;
    font-size: 16px;
}
.additional-service-resp{
    display: none;
}




/*  RESPONSIVE  */

/* * ExtraLarge: 1600px - 2000px */ 
@media (max-width: 2000px){
    .container {
		max-width: 1440px;
	}
}
/* * ExtraLarge2: 1490px - 1600px мой ноут */
@media (max-width: 1600px){
    .container {
		max-width: 1280px;
	}


}
/* * ExtraLarge3: 1199px - 1490px */ 
@media (max-width: 1490px){
    .container {
		max-width: 1110px;
	}
    .select-arrow{
        top: 7px;
    }

}

/* * Large: 992px - 1199px */ 
@media (max-width: 1199px){
    .container {
		max-width: 960px;
	}
    .branding-tarifs {
        justify-content: center;
    }
    .additional-services-wrap {
        width: 100%;
    }
    .additional-service-subdesc {
        width: 100%;
    }

}

/* Medium: 768px - 991px */
@media (max-width: 991px) {
	.container {
        width: 100%;
		max-width: 720px;
	}
    .rules-column{
        width: 100%;
    }
    .rules-column-one{
        margin-bottom: 50px;
    }
    .branding-item{
        width: 100%;
    }



}

/* Small: 576px - 767px */
@media (max-width: 767px) {
	.container {
		max-width: 540px;
	}
    .service-price-item {
        margin-left: -20px;
        font-size: 14px;
    }
    .service-item{
        font-size: 14px;
    }
    .price-value{
        font-size: 14px;
    }
    .additional-service-subdesc{
        font-size: 14px;
    }
    /* .service-price-line{
        top: 13px;
    } */
    .rules-column-title:before {
        transform: scale(0.6);
        top: -15px;
        left: 20px;
    }
    section{
        margin-bottom: 150px;
    }
    .select-arrow{
        top: 2px;
    }

}

/* ExtraSmall1: 576px - 500px */
@media (max-width: 575px) {
	.container {
        max-width: 490px;
	}  
    /* .additional-services-wrap{
        display: none;
    } */
    .additional-service-resp{
        display: block;
    }
    .service-price-line{
        display: none;
    }
    .service-title-wrap{
        display: flex;
        justify-content: space-between;
    }
    .service-item-resp{
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }
    .service-price-item-resp{
        margin-top: 0;
    }
    .service{
        width: 80%;
        text-indent: -20px;
        padding-left: 20px;
    }
    .service::before {
        content: '—';
        color: #01EB81;
        margin-right:5px;
    }
    /* .header{
        padding-top: 100px;
        margin-bottom: 50px;
    } */
    .condition-header-title{
        margin-top: 80px;
        margin-bottom: 0;
    }
    section{
        margin-bottom: 80px;
    }
    .additional-service-subdesc{
        margin-top: 50px;
    }
    .tarif-time{
        font-size: 16px;
    }
    select.currency-select{
        font-size: 20px;
    }


}

/* ExtraSmall2: 500px - 400px */
@media (max-width: 500px) {
	.container {
		max-width: 390px;
	}  


}

/* ExtraSmall3: 400px - 380px */
@media (max-width: 400px) {
	.container {
		padding: 0 15px;
        max-width: 100%;
	} 

}
/* ExtraSmall4: 380px - 230px */
@media (max-width: 380px) {
	.container {
		padding: 0 15px;
        max-width: 100%;
	}  
    .service{
        width: 70%;
    }


}
/* ExtraSmall5: 380px - 230px */
@media (max-width: 380px) {

}