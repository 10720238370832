section.form{
    overflow: hidden;
    padding-top: 150px;
    margin-bottom: 140px;
}
.home-btn{
    margin-left: 250px;
}
.form-slider-wrapper{
    position: relative;
}
/* .form-slider{
    display: none;
} */
/* .form-slider.active{
    display: block;
} */

.error-message{
    display: none;
    width: 50%;
    margin: 20px auto 0;
    font-size: 18px;
    color: red;
}
.shown{
    display: block;
}
.form-title-wrapper{
    width: 1250px;
    margin: 0 248px 0;
    position: relative;
}
.form-desc-wrapper{
    display: flex;
}
.form-title-one{
    width: 1050px;
}
.form-title-two{
    width: 900px;
    margin-top: 20px;
}
.form-description{
    width: 261px;
    font-size: 20px;
    margin-top: 25px;
}
.form-body{
    width: 695px;
    margin: 120px 367px 0;
}
.form-legend{
    width: 500px;
    margin-bottom: 60px;
}
.checkbox-wrapper{
    margin-bottom: 27px;
}
.checkbox-wrapper:last-child{
    margin-bottom: 0;
}
.checkbox-wrapper > input{
    display: none;
    width: 100px;
    height: 100px;
}
.checkbox-error{
    font-size: 12px;
    color: red;
}

.checkbox-wrapper > label{
    position: relative;
    padding-left: 55px;
    user-select: none;
    display: inline-block;
    /* cursor: none; */
}
.checkbox-wrapper > label::before{
    content: "";
	display: inline-block; 
	width: 30px;
	height: 30px;
	position: absolute;
	left: 0px;
    bottom: 5px;
	border: 1px solid #F1F1F1;
    background-image: none;
    border-radius: 2px;
    transition: background-image .5s ease-in-out, border .5s ease-in-out;
}
.checkbox-wrapper-policy > label::before{
    bottom: -5px;
}

.checkbox-wrapper > input:checked + label:before {
    background-image: url('../../components/image/checked.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 18px 22px;
    border: 1px solid #01EB81;
    transition: background-image .5s ease-in-out, border .5s ease-in-out;
}
.checkbox-wrapper > input:hover + label:before {
    border: 1px solid #01EB81;
	transition: background-image .5s ease-in-out, border .5s ease-in-out;
}
.checkbox-wrapper a{
    color: #01EB81;
}
.checkbox-wrapper-policy>label{
    font-size: 18px;
}

.radio-wrapper {
    display: flex;
    flex-direction: column;
}
.radio-wrapper > input+label{
    margin-bottom: 27px;
}
.radio-wrapper > label::before{
    content: "";
	display: inline-block; 
	width: 30px;
	height: 30px;
	position: absolute;
	left: 0px;
    bottom: 5px;
	border: 1px solid #F1F1F1;
    background-color: transparent;
    border-radius: 50px;
    transition: background-color .5s ease-in-out, border .5s ease-in-out;
}

.radio-wrapper > input:checked + label:before {
    background-color: #01EB81;
    background-image: none;
    transition: background-color .5s ease-in-out, border .5s ease-in-out;
}
.radio-wrapper > input:hover + label:before {
    border: 1px solid #01EB81;
	transition: background-color .5s ease-in-out, border .5s ease-in-out;
}

.arrow{
    background-image: url('../../components/image/arrow.svg');
    transition: bacground-image .5s ease-in-out;
    width: 52px;
    height: 52px;
    position: absolute;
    opacity: 1;
    transition: opacity .5s ease-in-out;
}
.arrow::before{
    content: '';
    background-image: url('../../components/image/arrow-hover.svg');
    width: 52px;
    height: 52px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity .5s ease-in-out;
}
.arrow-next_form{
    transform: rotate(180deg);
    bottom: 0;
    right: 0;
}
.arrow-prev_form{
    top: 350px;
    left: 250px;
}
.arrow:hover:before{
    opacity: 1;
    transition: opacity .5s ease-in-out;
}
.unshown{
    display: none;
}
.shown{
    display: block;
}
.slider-dots{
    position: absolute;
    right: 0;
    top: 60%;
}
.dot{
    height: 40px;
    width: 5px;
    background-color: #F1F1F1;
    opacity: 0.5;
    z-index: 100;
    margin-bottom: 25px;
    border-radius: 50px;
}
.dot.active{
    opacity: 1;
}
textarea#textarea-validate{
    width: 100%;
    height: 250px;
    border: 1px solid #F1F1F1;
    border-radius: 30px;
    background-color: #131313;
    color: #F1F1F1;
    padding-top: 20px;
    padding-left: 20px;
    font-family: "CeraPro";
    font-weight: 300;
    font-size: 24px;
    overflow: hidden;
    resize: none;
}
textarea#textarea-validate::placeholder{
    font-family: "CeraPro";
    font-weight: 300;
    font-size: 24px;
    width: 100%;
    opacity: 0.7;
}
.form-legend-contact{
    margin-bottom: 30px;
}
.form-body > input{
    font-family: 'CeraPro';
    width: 100%;
    height: 60px;
    padding-left: 30px;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    margin-bottom: 15px;
    font-size: 18px;
    color: #F1F1F1;
}
.form-body > .country{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.form-body > .country > input{
    font-family: 'CeraPro';
    width: 67%;
    height: 60px;
    padding-left: 30px;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    margin-bottom: 15px;
    font-size: 18px;
    color: #F1F1F1;
}
.form-body > .country > input::placeholder{
    opacity: 0.7;
}
.form-body > .country > .select-wrapper{
    width: 30%;
    height: 60px;
    background-color: transparent;
    border: 1px solid #F1F1F1;
    border-radius: 8px;
    font-size: 18px;
    color: #F1F1F1;
    font-family: 'CeraPro';
    display: flex;
    align-items: center;
    position: relative;
}
.form.loading{
    border: 1px solid red;
}
.select-wrapper > select{
    width: 100%;
    height: 100%;
    padding-left: 30px;
    background: transparent;
    border-radius: 8px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-size: 18px;
    color: #F1F1F1;
    font-family: 'CeraPro';
    /* cursor: none; */
    opacity: 0.7;
}
.select-wrapper > select::-ms-expand{
    display: none;
}
.select-arrow {
    position: absolute;
    z-index: -1;
    background: url('../../components/image/select-arrow.svg') no-repeat center;
    width: 15px;
    height: 15px;
    right: 17px;
}
select > option{
    width: 200px;
    height: 60px;
    margin-bottom: 20px;
    background-color: #131313;
    border: 1px solid #F1F1F1;
    border-radius: 20px;
    font-size: 18px;
    color: #F1F1F1;
    font-family: 'CeraPro';
    padding-left: 30px;
    display: flex;
    align-items: center;
}

.form-body > input::placeholder{
    opacity: 0.7;
}
.submit-button{
    margin-top: 36px;
    font-weight: 400;
    font-size: 32px;
    width: 100%;
}
.submit-button[disabled]{
    opacity: 0.2;
}
.submit-success-wrapper{
    display: none;
}
.submit-success-wrapper.shown{
    display: block;
}
.overlay{
    position:fixed;
    z-index: 999;
    top:0;
    right:0;
    left:0;
    bottom:0;
    background:#000;
    opacity:0.6;
}
.submit-success{
    z-index: 1000;
    position: fixed;
    width: 943px;
    height: 670px;
    margin-left: -471.5px;
    margin-top: -335px;
    background: #01EB81;
    border: 1px solid #F1F1F1;
    border-radius: 40px;
    top: 50%;
    left: 50%;
    color: #131313;
    text-align: center;
}
.success-title{
    margin-bottom: 54px;
    margin-top: 75px;
    line-height: 90px;
}
.success-text{
    font-size: 30px;
    width: 546px;
    text-align: center;
    margin: 0 auto 35px;
    line-height: 32px;
}
.success-social{
    display: flex;
    width: 209px;
    justify-content: space-between;
    margin: 0 auto;
}
.success-social > .instagram{
    background: url('../../components/image/InstagramSuccess.svg') no-repeat center;
    width: 77px;
    height: 77px;
    transform: scale(1);
    transition: transform .5s ease-in-out;
}
.success-social > .behance{
    background: url('../../components/image/BehanceSuccess.svg') no-repeat center;
    width: 77px;
    height: 77px;
    transform: scale(1);
    transition: transform .5s ease-in-out;
}
.success-social > a:hover{
    transform: scale(1.1);
    transition: transform .5s ease-in-out;
}
.success-button{
    border: 1px solid #131313;
    border-radius: 8px;
    color: #131313; 
    width: 570px;
    height: 80px;
    font-size: 32px;
    margin: 60px auto 0;
}
.success-button:hover{
    background: #F1F1F1;
}
.submit-success>.overlay{
    position:fixed;
    z-index: 999;
    top:0;
    right:0;
    left:0;
    bottom:0;
    background:#131313;
    opacity:0.5;
}

footer.footer{
    padding: 60px 0;
}


/*  RESPONSIVE  */

/* * ExtraLarge: 1600px - 2000px */ 
@media (max-width: 2000px){
    .container {
		max-width: 1440px;
	}

}
/* * ExtraLarge2: 1490px - 1600px мой ноут */
@media (max-width: 1600px){
    .container {
		max-width: 1280px;
	}
    .error-message{
        width: 80%;
    }
    .form-title-wrapper{
        margin: 0;
        padding-left: 100px;
    }
    .form-description{
        margin-left: -150px;
    }
    .form-body{
        margin: 0;
        padding-left: 100px;
        margin-top: 120px;
    }
    .arrow-prev_form{
        top: 350px;
        left: 0px;
    }

}
/* * ExtraLarge3: 1199px - 1490px */ 
@media (max-width: 1490px){
    .container {
		max-width: 1110px;
	}
    .form-desc-wrapper{
        display: block;
    }
    .form-description{
        margin-left: 0px;
        width: 90%;
    }
    .form-title-wrapper{
        width: 100%;
    }
}

/* * Large: 992px - 1199px */ 
@media (max-width: 1199px){
    .container {
		max-width: 960px;
	}
}

/* Medium: 768px - 991px */
@media (max-width: 991px) {
	.container {
        width: 100%;
		max-width: 720px;
	}
    .form-title-wrapper{
        width: 90%;
    }
    .form-title-one{
        width: 100%;
    }
    .form-title-two {
        width: 720px;
        margin-top: 0px;
    }
    .form-body{
        margin-top: 60px;
        width: 90%;
    }
    .slider-dots{
        top: 50%;
        margin-top: 20px;
    }
    .submit-success{
        width: 100%;
        margin-left: -50%;
    }
    .submit-button{
        width: 100%;
    }


}

/* Small: 576px - 767px */
@media (max-width: 767px) {
	.container {
		max-width: 540px;
	}
    .form-legend{
        width: 90%;
    }
    .form-title-wrapper{
        padding-left: 0;
    }
    .form-body{
        padding-left: 0;
    }
    .slider-arrow{
        padding-top: 100px;
    }
    .arrow-next_form{
        bottom: 0px;
        right: 0;
    }
    .arrow-prev_form{
        top: auto;
        left: 0;
        bottom: 0px;
    }
    .checkbox-wrapper > label::before {
        bottom: -5px;
    }
    .success-button{
        width: 80%;
    }
    .success-text{
        font-size: 20px;
        width: 80%;
    }
    textarea#textarea-validate{
        font-size: 18px;
    }
    textarea#textarea-validate::placeholder{
        font-size: 18px;
    }
    .select-wrapper > select {
        width: 100%;
        height: 100%;
        padding-left: 10px;
    }
    .select-arrow {
        right: 10px;
    }
    .submit-button{
        font-size: 24px;
    }
}

/* ExtraSmall1: 576px - 500px */
@media (max-width: 575px) {
	.container {
        max-width: 490px;
	}  
    section.form{
        margin-bottom: 200px;
    }

}

/* ExtraSmall2: 500px - 400px */
@media (max-width: 500px) {
	.container {
		max-width: 390px;
	}  
    .select-wrapper > select {
        padding-left: 5px;
    }
    .select-arrow {
        right: 5px;
    }
    .submit-button{
        font-size: 16px;
    }
    footer.footer{
        padding: 120px 0 60px;
    }
    section.form{
        margin-bottom: 300px;
    }

}

/* ExtraSmall3: 400px - 380px */
@media (max-width: 400px) {
	.container {
		padding: 0 15px;
        max-width: 100%;
	} 

}
/* ExtraSmall4: 380px - 230px */
@media (max-width: 380px) {
	.container {
		padding: 0 15px;
        max-width: 100%;
	}  
    .select-wrapper > select {
        padding-left: 5px;
        font-size: 16px;
    }
    .select-arrow {
        right: 0px;
        transform: scale(0.6);
    }

}
/* ExtraSmall5: 380px - 230px */
@media (max-width: 380px) {

}