*{
    cursor: none;
}
.ipl-progress-indicator.available {
    opacity: 0;
  }
  .ipl-progress-indicator {
    background-color: #131313;
    width: 100%;
    height: 100%;
    position: fixed;
    opacity: 1;
    pointer-events: none;
    -webkit-transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
    -moz-transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
    transition: opacity cubic-bezier(0.4, 0, 0.2, 1) 436ms;
    z-index: 9999;
  }
  .preloader-wrapper{
    width: 100%;
    height: 100%;
    position: relative;
  }
  .preloader-circule{
    background: url('../image/cursor-main.svg');
    width: 80px;
    height: 80px;
    top: 50%;
    left: 50%;
    margin-left: -40px;
    margin-top: -40px;
    background-size: cover;
    position: absolute;
    pointer-events: none;
    z-index: 10000;
  }
  .preloader-dot{
    border-radius: 50%;
    color: #01EB81;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -5px;
    margin-top: -5px;
    pointer-events: none;
    z-index: 10000;
    -webkit-animation: load4 1.3s infinite linear;
    animation: load4 1.3s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  @-webkit-keyframes load4 {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }
  @keyframes load4 {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }

.cursor-wrapper{
    position: relative;
}
.cursor-auto{
    background: url('../image/cursor-main.svg');
    width: 25px;
    height: 25px;
    background-size: cover;
    position: absolute;
    pointer-events: none;
    z-index: 2000;
}
.cursor-auto-addition{
    background: url('../image/cursor-main-add.svg');
    width: 9.7px;
    height: 9.7px;
    background-size: cover;
    position: absolute;
    top: -20px;
    left: 10.15px;
    pointer-events: none;
    animation-delay: 1s;
    opacity: 1;
    transition: opacity .5s ease-in-out;
    z-index: 2000;
}
.cursor-auto.cursor-pointer{
    background: url('../image/cursor-pointer.svg');
    width: 25px;
    height: 25px;
    background-size: cover;
}
.cursor-auto-addition.cursor-pointer{
    opacity: 0 !important;
    transition: opacity .5s ease-in-out;
}

@media (max-width: 1490px){
  .preloader-circule{
    width: 60px;
    height: 60px;
    margin-left: -30px;
    margin-top: -30px;
  }
}

@media (max-width: 767px) {
  .preloader-circule{
    width: 40px;
    height: 40px;
    margin-left: -20px;
    margin-top: -20px;
  }
}