section.portfolio{
    padding-top: 150px;
}
section.portfolio_main{
    padding-top: 0;
}
.portfolio-wrapper{
    overflow: hidden;
}
/* .portfolio-item{
    display: none;
} */
.portfolio-title-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 120px;
}
.portfolio-filter-wrapper{
    width: 571px;
    text-align: center;
    position: relative;
}
.portfolio-item:first-child>.portfolio-item-text>.portfolio-item-title{
    width: 320px;
}
.filter-open{
    color: #01EB81; 
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 400;
    border: 1px solid #01EB81;
    border-radius: 12px;
    width: 100%;
    height: 80px;
    position: relative;
    z-index: 3;
    /* cursor: none; */
    display: flex;
    align-items: center;
    padding-left: 35%;
}
.filter-btn{
    display: flex;
    justify-content: center;
    width: 100%;
}
.filter-open::after{
    content: '';
    position: absolute;
    background: url('../../components/image/filter-open.svg') no-repeat center;
    background-size: fill;
    width: 20px;
    height: 20px;
    top: 48%;
    margin-top: -10px;
    right: 230px;
    transform: rotate(0deg);
    transition: transform .5s ease-in-out;
}
.filter-open.opened::after{
    transform: rotate(45deg);
    transition: transform .5s ease-in-out;
}
.filter-btn-wrapper{
    display: block;
    position: absolute;
    top: 71px;
    left: 0;
    width: 100%;
    border: 1px solid #01EB81;
    border-top: none;
    z-index: 2;
    border-radius: 0 0 12px 12px;
    background-color: #131313;
    visibility: hidden;
    opacity: 0;
    transition: visibility .5s ease-in-out, opacity .5s ease-in-out;
}
.filter-btn-wrapper.opened{
    visibility: visible;
    opacity: 1;
    transition: visibility .5s ease-in-out, opacity .5s ease-in-out;
}
.filter-btn{
    display: flex;
    text-align: center;
    color: #f1f1f1;
    text-transform: uppercase;
    font-size: 24px;
    margin: 0 auto;
    font-weight: 400;
    padding-top: 10px;
    transition: color .5s ease-in-out;
    /* cursor: none; */
}
.filter-btn:hover{
    color: #01EB81;
    transition: color .5s ease-in-out;
}
.filter-btn:first-child{
    padding-top: 34px;
}
.filter-btn:last-child{
    padding-bottom: 25px;
}
.show{
    display: flex;
}
.show-block{
    display: block;
}
.active{
    color: #01EB81;
}
.success-button.active{
    color: #131313;
}



/*  RESPONSIVE  */

/* * ExtraLarge: 1600px - 2000px */ 
@media (max-width: 2000px){
    .container {
		max-width: 1440px;
	}
}
/* * ExtraLarge2: 1490px - 1600px мой ноут */
@media (max-width: 1600px){
    .container {
		max-width: 1280px;
	}
    /* .portfolio-item:first-child>.portfolio-item-text>.portfolio-item-title {
        width: 250px;
    } */

}
/* * ExtraLarge3: 1199px - 1490px */ 
@media (max-width: 1490px){
    .container {
		max-width: 1110px;
	}
}

/* * Large: 992px - 1199px */ 
@media (max-width: 1199px){
    .container {
		max-width: 960px;
	}
    .portfolio-item:first-child>.portfolio-item-text>.portfolio-item-title {
        width: 250px;
    }
    .portfolio-title-wrapper{
        display: block;
        margin-bottom: 60px;
    }
    .portfolio-title{
        margin-bottom: 35px;
    }
    .portfolio-filter-wrapper {
        width: 70%;
    }
    .filter-open::after {
        right: auto;
        left: 45%;
        margin-left: 50px;
    }
}

/* Medium: 768px - 991px */
@media (max-width: 991px) {
	.container {
        width: 100%;
		max-width: 720px;
	}
    section.portfolio{
        margin-top: 0;
        padding-top: 160px;
    }
    .portfolio-item:first-child>.portfolio-item-text>.portfolio-item-title {
        width: auto;
    }

}

/* Small: 576px - 767px */
@media (max-width: 767px) {
	.container {
		max-width: 540px;
	}
    .filter-open{
        font-size: 20px;
    }
    .filter-btn{
        font-size: 20px;
    }
 

}

/* ExtraSmall1: 576px - 500px */
@media (max-width: 575px) {
	.container {
        max-width: 490px;
	}

}

/* ExtraSmall2: 500px - 400px */
@media (max-width: 500px) {
	.container {
		max-width: 390px;
	}  
    .portfolio-filter-wrapper {
        width: 100%;
    }
    section.portfolio {
        padding-top: 80px;
        margin-bottom: 100px;
    }
    footer.footer {
        margin-top: -250px;
    }
}

/* ExtraSmall3: 400px - 380px */
@media (max-width: 400px) {
	.container {
		padding: 0 15px;
        max-width: 100%;
	} 

}
/* ExtraSmall4: 380px - 230px */
@media (max-width: 380px) {
	.container {
		padding: 0 15px;
        max-width: 100%;
	}  

}
/* ExtraSmall5: 380px - 230px */
@media (max-width: 380px) {

}