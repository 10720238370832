#cookie_note{
    position: fixed;
    bottom: 15px;
    left: 50%;
    max-width: 100%;
    margin: 0 auto;
    transform: translateX(-50%);
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.4);
}

#cookie_note p{
    margin: 0;
    font-size: 1rem;
    text-align: left;
    color: #131313;
    width: 80%;
}
#cookie_note p a{
    color: #01EB81;
    cursor: none;
}

.cookie_accept{
    width:20%;
    height: 40px;
    cursor: none;
    font-size: 1rem;
    color: #131313;
    border: 1px solid #131313;
}
.cookie_note-wrapper{
    display: flex;
}
@media (max-width: 1200px){
    .cookie_note-wrapper{
        flex-direction: column;
        text-align: center;
        align-items: center;
    }
    #cookie_note p{
        width: 100%;
    }
    .cookie_accept{
        width:80%;
        margin-top: 20px;
        margin-left: 0;
    }
}
@media (max-width: 575px){
    #cookie_note.show{
        display: block;
        text-align: left;
    }
}